import { useMemo } from "react";
import { useUser } from "../../../lib/index";

export function useCertificates() {
  const user = useUser();
  const { reader_profile_certificates: certificates } = user.data.content;

  return useMemo(() => {
    if (!certificates) {
      return [];
    }

    return certificates.map(certificate => {
      const {
        reader_profile_certificates_uuid: id,
        reader_profile_certificates_name: name,
        reader_profile_certificates_description: description,
        reader_profile_certificates_image: image,
      } = certificate;

      return { id, name, description, image };
    });
  }, [certificates])
}
