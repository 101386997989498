import { Navigate, Outlet, useLocation } from "react-router-dom";
import { makeStyles, Grid, Box } from "@material-ui/core";
import {
  PlayCircleOutlineOutlined,
  FilterTiltShiftOutlined,
  CardMembershipOutlined,
  PermContactCalendarOutlined,
  DateRangeOutlined,
  AccountBalance,
  Group,
  Visibility
} from "@material-ui/icons";

import { theme } from "../../lib/index";
import { LinkList, LinkListItem } from "../../components";

const useStyles = makeStyles({
  submenu: {
    position: "fixed",
    left: 0,
    top: 0,
    zIndex: "9",

    paddingTop: theme.submenuPaddingTop,
    paddingRight: 0,
    paddingLeft: 0,
    width: theme.submenuWidthDesktop,
    height: "100%",

    background: theme.palette.darkgrey,

    color: theme.palette.white,

    transform: `translateX(${theme.menuWidthDesktop}px)`,
  },
  
  page: {
    width: "100%",
    paddingLeft: theme.submenuWidthDesktop,
  },

  grid: {
    margin: "0 auto",
    maxWidth: `calc(100vw - ${theme.menuWidthDesktop}px - ${theme.submenuWidthDesktop}px - (2 * ${theme.contentPagePaddingSides}px))`,

    background: theme.palette.white,
  },
});

export default function Profile() {
  const classes = useStyles();
  const location = useLocation();

  return (
    location.pathname === "/profile" || location.pathname === "/profile/"
      ? <Navigate to="/profile/personal" state={{ from: location }} replace />
      : <>
        <Box className={classes.submenu}>
          <LinkList>
            <LinkListItem
              name="Steckbrief"
              path="/profile/personal"
              icon={<PermContactCalendarOutlined />}
            />
            <LinkListItem
              name="Social Media"
              path="/profile/socialmedia"
              icon={<Group />}
            />
            <LinkListItem
              name="Schwerpunkte"
              path="/profile/focus"
              icon={<FilterTiltShiftOutlined />}
            />
            <LinkListItem
              name="Vision"
              path="/profile/vision"
              icon={<Visibility />}
            />
            <LinkListItem
              name="Zertifikate"
              path="/profile/certificate"
              icon={<CardMembershipOutlined />}
            />
            <LinkListItem
              name="Medien"
              path="/profile/media"
              icon={<PlayCircleOutlineOutlined />}
            />
            <LinkListItem
              name='Kalender'
              path='/profile/calendar'
              icon={<DateRangeOutlined />}
            />
            <LinkListItem
              name='Finanzielles'
              path='/profile/billing'
              icon={<AccountBalance />}
            />
          </LinkList>
        </Box>
        <Box
          className={classes.page}
        >
          <Grid
            className={classes.grid}
            container
            columns={24}
            spacing={5} 
            justifyContent="flex-start"
          >
            <Outlet />
          </Grid>
        </Box>
      </>
  );
}
