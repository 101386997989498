import { Form, Field } from "react-final-form";

import { Container, makeStyles, Typography } from "@material-ui/core";
import { Error } from "@material-ui/icons";

import { Btn, CardHdc, Headline, InputTextfieldFF } from "../../components/index";

import { theme, constants as c } from "../../lib/index";
import logo from "../../assets/images/logo.png";

const useStyles = makeStyles({
  page: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 100,

    display: "flex",
    overflow: "hidden",
    minWidth: "100vw",
    minHeight: "100vh",

    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    background: theme.palette.white,
  },

  formContainer: {
    display: "flex",
    padding: "5vh 100px 5vh",
    maxWidth: 1000,

    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    background: theme.palette.white,
  },

  logo: {
    marginTop: 20,
    width: "100%",
    maxWidth: 164,
    minHeight: 67,

    background: `url(${logo})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    filter: "invert(100%)",
  },

  headline: {
    margin: "50px 0",
  },

  form: {
    display: "flex",
    marginBottom: 50,

    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
});

const fields = [
  {             
    name: "user",
    placeholder: "Nutzername",
    tabindex: "0",
    type: "text"
  },
  {
    name: "pwd",
    placeholder: "Passwort",
    type: "password"
  }
]

export default function LoginPage({ handleLogin, failed, }) {
  const classes = useStyles();
  return (
    <Container className={classes.page}>
      <CardHdc shadow className={classes.formContainer}>
        
        <div className={classes.logo} />
        <Typography> Reader Selfservice </Typography>
        <Headline className={classes.headline}>Willkommen</Headline>

        <Form
          initialValues={[]}
          onSubmit={(formdata) =>
            handleLogin({
              username: formdata.user,
              password: formdata.pwd,
            })
          }
          render={({ handleSubmit }) => (
            <form className={classes.form} onSubmit={handleSubmit}>
              {fields.map((field) => {
                return <Field
                  component={InputTextfieldFF}
                  key={field.key || field.name}
                  login
                  name={field.name}
                  onKeyPress={handleSubmit}
                  placeholder={field.placeholder}
                  tabindex={field.tabindex || undefined}
                  type={field.type}
                  required
                />
              })}
              <Btn
                className={classes.button}
                flavor={c.BLACK}
                onClick={handleSubmit}
                type="submit"
              >
                  einloggen
              </Btn>
            </form>
          )}
        />

        {failed && (
          <>
            <Error color="error" fontSize="large" />
            <Typography variant="h6">Name oder Passwort fehlerhaft.</Typography>
            <Typography variant="h6">Login nicht möglich.</Typography>
          </>
        )}

      </CardHdc>
    </Container>
  )
}
