import { useState, useEffect } from "react";
import { Form, Field } from "react-final-form";
import { v4 as uuid } from "uuid"
import { Add } from "@material-ui/icons";
import { makeStyles, Typography, Box } from "@material-ui/core";
import { Stack } from "@mui/material";
import { usePatchUser, theme, validation as val, constants as c, loadMedia } from "../../lib/index";
import { Image, ModalHdc, UploadButton, EscOrSaveButtons, InputTextfieldFF, DatepickerFF } from "../../components/index";
import StarRatingFF from "./StarRatingFF";
import { useRatings } from './useRatings';
import { formatRatingsForApi } from './formatRatingsForApi';

const useStyles = makeStyles({
  card: {
    position: "relative",
    margin: "15px 0 0",
    padding: "30px 60px 0",

    background: theme.palette.white,

    "& .MuiContainer-root": {
      marginBottom: 25,
    },

    "& .MuiButton-root": {
      margin: "15px 0 25px 0",
    },

    "& .MuiBox-root": {
      margin: 0,
    },
  },
  innerBox: {
    marginTop: 30,
    flexDirection: "row",
    gap: "20px",

    "& .inputBox": {
      maxWidth: "50%",
      minWidth: "40%"
    },
    "& .imageBox": {
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      paddingLeft: "20px",
    }
  },

  image: {
    maxHeight: 200,
    maxWidth: 200,
  },

  subSubline: {
    ...theme.typography.pSmall,
    maxWidth: "40%",

    color: theme.palette.placeholdergrey,
  },
});

export default function ModalCreateRating() {
  const { mutateAsync: patchUser } = usePatchUser()
  const ratings = useRatings()

  const [uploadedImageId, setUploadedImageId] = useState("");
  const [image, setImage] = useState({});
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => {
    setUploadedImageId("");
    setImage({});
    setOpen(false);
  };

  useEffect(() => {
    uploadedImageId && loadMedia(uploadedImageId, setImage);
  }, [uploadedImageId]);

  const classes = useStyles();

  function postRating(newRating) {
    const nextRatings = [...ratings, newRating]
    const formatted = formatRatingsForApi(nextRatings);
    const patchedData = { reader_rating: formatted }

    return patchUser(patchedData)
  }

  const onSubmit = async (data) => {
    data = {
      id: uuid(),
      ...data,
      link: data.link ? data.link : "",
      src: uploadedImageId,
    };
    await postRating(data);
    setUploadedImageId("");
    setImage({});
    setOpen(false);
  };

  const handleImageUpload = (data) => {
    setUploadedImageId(data);
    loadMedia(data, setImage);
  };

  return (
    <ModalHdc
      buttonName="Neue Bewertung hinzufügen"
      buttonType={c.BLACK}
      className={classes.modal}
      fullWidth
      handleClickOpen={handleClickOpen}
      handleClose={handleClose}
      open={open}
      startIcon={<Add />}
    >
      <Box className={classes.card}>
        <Typography variant="h3">Neue Bewertung hinzufügen</Typography>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, dirty, form }) => (
            <form
              onSubmit={(event) => handleSubmit(event)?.then(()=>{form.reset();})}
            >
              <Stack className={classes.innerBox}>
                <Box className="inputBox">
                  <Field
                    component={InputTextfieldFF}
                    label="Name"
                    name="title"
                    fullWidth
                    placeholder="Name"
                    required
                    validate={val.composeValidators(val.required, val.mustBeText)}
                  />
                  <Field
                    component={DatepickerFF}
                    label="Datum der Bewertung"
                    name="date"
                    placeholder="TT.MM.JJJJ"
                    required
                    validate={val.mustBeDate}
                  />
                  <Field
                    component={StarRatingFF}
                    isInput
                    name="stars"
                    required
                    type="text"
                    validate={val.required}
                  />
                </Box>
                <Stack className="imageBox">
                  
                  { image.src
                    ? <Image circle className={classes.image} src={image.src}/>
                    : (
                      <Stack justifyContent="center" alignItems="center">
                        <UploadButton image handleUploadedFile={handleImageUpload}>
                        Bild hochladen
                        </UploadButton>
                        <Typography className={classes.subSubline}>
                              .jpg-, .jpeg, .png oder .webp-Format, max. 1MB
                        </Typography>
                      </Stack>
                    )}
                </Stack>
              </Stack>
              <Field
                component={InputTextfieldFF}
                fullWidth
                label="Social Media"
                name="link"
                placeholder="https://www..."
                validate={val.mustBeUrl}
              />
              <Field
                component={InputTextfieldFF}
                fullWidth
                label="Bewertungstext"
                multiline
                name="text"
                placeholder="Bewertung"
                required
                rows={5}
                validate={val.composeValidators(val.required, val.mustBeText)}
              />
              <EscOrSaveButtons
                modal
                disabled={!dirty}
                handleClose={handleClose}
                typeSave={"submit"}
              />
            </form>
          )}
        />
      </Box>
    </ModalHdc>
  );
}
