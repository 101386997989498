
import ReactDOM from 'react-dom';
import { StyledEngineProvider } from '@mui/material/styles';
import { App } from './app/index';
import './index.css';

ReactDOM.render(
  //<React.StrictMode>
  <StyledEngineProvider injectFirst>
    <App />
  </StyledEngineProvider>,
  //</React.StrictMode>,
  document.getElementById('root')
);