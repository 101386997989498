import { useMemo } from 'react'
import { useUser } from '../../../lib/index';
import { computeActiveSocialMedia } from './computeActiveSocialMedia'
import { computeInactiveSocialMedia } from './computeInactiveSocialMedia'

export function useActiveAndInactiveSocialMedia() {
  const user = useUser();
  const { reader_profile_description_socials: rawSocials } = user.data.content;

  return useMemo(() => {
    const activeSocialsList = computeActiveSocialMedia(rawSocials)
    const inactiveSocialsList = computeInactiveSocialMedia(rawSocials)

    return { activeSocialsList, inactiveSocialsList };
  }, [rawSocials])
}
