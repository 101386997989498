import { useState } from "react";
import { Grid, makeStyles, Typography, Snackbar } from "@material-ui/core";
import { theme } from "../../lib/index";
import { Headline } from "../../components/index";
import TableCustomerData from "./TableCustomerData";
import ModalCustomer from "./ModalCustomer";
import CardFormCustomerData from "./CardFormCustomerData";
import { useCustomers } from './useCustomers.js'

const useStyles = makeStyles({
  fixed: {
    position: "initial",

    [theme.breakpoints.up("lg")]: {
      position: "sticky",
      top: 100,
    },
  },

  card: {
    position: "relative",
    padding: "30px 60px 35px 60px",

    background: theme.palette.white,

    "& .MuiContainer-root": {
      marginBottom: 25,
    },

    "& .MuiBox-root": {
      margin: 0,
    },
  },
});

export default function Coisar() {
  const customers = useCustomers()
  const [openCustomerModal, setOpenCustomerModal] = useState(false);
  const [confirmSave, setConfirmSave] = useState(false);
  const [chosenClientId, setChosenClientId] = useState("");
  const chosenClient = customers.find((customer) => customer.id === chosenClientId);
  const classes = useStyles();
  
  // open ModalCustomer to add new customer
  const handleClickOpen = () => {
    setChosenClientId("");
    setOpenCustomerModal(true);
  };
  
  // open modal and set data for editing customers
  const handleEditCustomer = (client) => {
    setChosenClientId(client.id);
    setOpenCustomerModal(true);
  };

  // close ModalCustomer
  const handleClose = () => setOpenCustomerModal(false);

  return (
    <>
      <Grid item xs={12}>
        <Headline>Coisar-Daten</Headline>
      </Grid>

      <Grid
        item
        xs={12} md={5} lg={6}
        style={{
          paddingRight: 20,
          transition: theme.transitions.create("all", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        }}
      >
        <ModalCustomer
          customerId={chosenClientId}
          customers={customers}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          open={openCustomerModal}
        />

        {customers.length === 0 && (
          <Typography>
            Bisher wurden keine Kundendaten eingetragen.
          </Typography>
        )}

        {customers.length !== 0 && (
          <TableCustomerData
            customers={customers}
            setChosenClientId={setChosenClientId}
          />
        )}
      </Grid>

      {chosenClient && (
        <Grid item xs={12} md={7} lg={6}>
          <CardFormCustomerData
            chosenClient={chosenClient}
            handleEditCustomer={handleEditCustomer}
          />
        </Grid>
      )}

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={2000}
        className={classes.snackbar}
        message="Änderungen gespeichert"
        open={confirmSave}
        onClose={() => setConfirmSave(false)}
      />
    </>
  );
}
