import { mapStateKeyToBackendKey, usePatchUser, typeOfReadingMap } from '../../lib/index'
import { useOffers } from './useOffers'

function mergeOfferData(offers, newOfferData) {
  
  if (!document.getElementById(newOfferData.id)) {
    return [...offers, newOfferData]
  }

  return offers.map((offer) => (
    offer.id !== newOfferData.id
      ? offer
      : newOfferData
  ))
}
  
export function useHandleNewOffer() {
  const offers = useOffers()
  const { mutateAsync: patchUser } = usePatchUser()
  
  return (newOfferData) => {
    const mergedData = mergeOfferData(offers, newOfferData)
    const mappedOffers = mergedData.map(offer => ({
      reader_offers_uuid: offer.id,
      reader_offers_type: mapStateKeyToBackendKey(offer.typeOfReading, typeOfReadingMap),
      reader_offers_price: offer.price,
      reader_offers_duration: offer.duration, 
      reader_offers_content: offer.additionalBenefits || "",
    }));
    
    const patchedData = { reader_offers: mappedOffers }
    console.log("newOfferData", patchedData)
    return patchUser(patchedData);
  }
}