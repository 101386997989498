import { useState } from "react";
import { Grid, Snackbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Headline } from "../../../components/index";
import { usePatchUser } from "../../../lib/index";
import TableHdc from "./TableHdc";
import ModalAddCertificate from "./ModalAddCertificate";
import { useCertificates } from './useCertificates'

const useStyles = makeStyles({
  snackbar: {
    "& .MuiSnackbarContent-root": {
      minWidth: "fit-content",
    }
  },
})

export default function Certificate() {
  const { mutateAsync: patchUser } = usePatchUser()
  const certificates = useCertificates()
  const [dataToEdit, setDataToEdit] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [confirmSave, setConfirmSave] = useState(false);
  const classes = useStyles();

  const handleDeleteCertificate = async (clicked) => {
    const data = certificates.filter((e) => e.id !== clicked);
    const mappedCertificates = data.map(c => ({
      reader_profile_certificates_uuid: c.id,
      reader_profile_certificates_name: c.name,
      reader_profile_certificates_description: c.description || "",
      reader_profile_certificates_image: c.image || 499,
    }))

    const patchedData = { reader_profile_certificates: mappedCertificates };
    await patchUser(patchedData)
    setConfirmSave(true)
  };

  // open modal and set data for editing certificate
  const handleEditCertificate = (row) => {
    setDataToEdit(row);
    setOpenModal(true);
  };

  const handleClickOpen = () => {
    setDataToEdit({});
    setOpenModal(true);
  };

  const handleClose = () => setOpenModal(false);

  return (
    <>
      <Grid item xs={12}>
        <Headline>Zertifikate</Headline>
        <ModalAddCertificate
          dataToEdit={dataToEdit}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          open={openModal}
        />
      </Grid>
      {certificates.length !== 0 &&<Grid item xs={12}>
        <TableHdc
          {...{ handleDeleteCertificate }}
          {...{ handleEditCertificate }}
          rows={certificates}
        />
      </Grid>}
      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={2000}
        open={confirmSave}
        onClose={() => setConfirmSave(false)}
        message="Änderungen gespeichert"
      />
    </>
  );
}
