import { makeStyles, Container, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { useState } from "react";
import { Form, Field } from "react-final-form";
import {
  DropDownFF,
  EscOrSaveButtons,
  InputTextfieldFF,
  ModalHdc,
} from "../../../components/index";
import {
  constants as c,
  socialMap,
  mapStateKeyToBackendKey,
  usePatchUser,
  validation,
} from "../../../lib/index";
import { useActiveAndInactiveSocialMedia } from './useActiveAndInactiveSocialMedia'

const { composeValidators, mustBeUrl } = validation;
const required = value => value ? undefined : "Bitte ausfüllen";

const useStyles = makeStyles({
  title: {
    paddingTop: 50,
    paddingLeft: 50,
  },

  container: {
    marginTop: 50,
    marginBottom: 35,
    width: 500,
  },
});

export default function ModalSocialMedia({ onAdded }) {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { activeSocialsList, inactiveSocialsList } = useActiveAndInactiveSocialMedia()
  const { mutateAsync: patchUser } = usePatchUser()
  const options = inactiveSocialsList.map(({ plattform }) => ({ choice: plattform }))

  const submitChanges = async ({ plattform, link }) => {
    const nextSocialMedia = [
      ...activeSocialsList,
      ...inactiveSocialsList,
      { plattform, link },
    ]

    const readerProfileDescriptionSocials = nextSocialMedia.reduce(
      (acc, cur) => ({
        ...acc,
        [mapStateKeyToBackendKey(cur.plattform, socialMap)]: cur.link,
      }),
      {}
    )

    const patchedData = {
      reader_profile_description_socials: readerProfileDescriptionSocials,
    };

    await patchUser(patchedData);
    handleClose();
    onAdded();
  };

  const classes = useStyles();

  return (
    <ModalHdc
      buttonName="Account hinzufügen"
      buttonType={c.BLACK}
      className={classes.modal}
      fullWidth
      handleClickOpen={handleClickOpen}
      handleClose={handleClose}
      open={open}
      startIcon={<Add />}
    >
      <Typography variant="h3" className={classes.title}>
        Social media account hinzufügen
      </Typography>
      <Container className={classes.container}>
        <Form
          onSubmit={submitChanges}
          render={({ handleSubmit, dirty }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Field
                  component={DropDownFF}
                  fullWidth
                  required
                  items={options}
                  label="Plattform"
                  name="plattform"
                  placeholder="Zu welcher Plattform möchtest du verlinken?"
                  type="text"
                  validate={required}
                />
                <Field
                  component={InputTextfieldFF}
                  fullWidth
                  required
                  label="Link zu deinem Profil"
                  name="link"
                  placeholder="https://www..."
                  type="text"
                  validate={composeValidators(required, mustBeUrl)}
                />
                <EscOrSaveButtons
                  disabled={!dirty}
                  handleClose={handleClose}
                  handleSave={(event) => handleSubmit(event)}
                  modal
                />
              </form>
            );
          }}
        />
      </Container>
    </ModalHdc>
  );
}
