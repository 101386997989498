import { Box, makeStyles } from "@material-ui/core";

export default function LinkList({ children }) {
  const useStyles = makeStyles({
    linklist: {
      paddingTop: 35,
      paddingLeft: 22,
      height: "100%",
    },
  });

  const classes = useStyles();

  return (
    <Box className={classes.linklist}>
      {children}
    </Box>
  );
}
