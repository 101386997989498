import { useQueryClient } from '@tanstack/react-query'
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, makeStyles, Typography } from "@material-ui/core";
import logo from "../../assets/images/logo.png";
import { CardHdc, Headline } from "../../components/index";
import { theme, useEncryptStorage } from "../../lib/index";


const useStyles = makeStyles({
  page: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 100,

    display: "flex",
    overflow: "hidden",
    minWidth: "100vw",
    minHeight: "100vh",

    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    background: theme.palette.white,
  },

  formContainer: {
    display: "flex",
    padding: "130px 50px 150px",
    maxWidth: 1000,

    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    background: theme.palette.white,
  },

  logo: {
    width: "100%",
    maxWidth: 164,
    minHeight: 67,

    background: `url(${logo})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    filter: "invert(100%)",
  },

  headline: {
    margin: "50px 0",
  },
});

export default function Logout() {
  const classes = useStyles();
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const credentials = useEncryptStorage(store => store.getCredentials())
  const setCredentials = useEncryptStorage(store => store.setCredentials)

  useEffect(() => {
    setTimeout(()=> {
      setCredentials({ username: null, password: null })
      queryClient.clear()
      navigate('/')
    }, 1000);
    console.log("logged out");
  }, [credentials, setCredentials, navigate, queryClient]);

  return (
    <Container className={classes.page}>
      <CardHdc shadow className={classes.formContainer}>
        <div className={classes.logo} />
        <Typography> Reader Selfservice </Typography>
        <Headline className={classes.headline}>auf Wiedersehen</Headline>
        <Typography variant="h5">du wirst zum Login weitergeleitet</Typography>
      </CardHdc>
    </Container>
  );
}
