import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Dialog, Box, makeStyles, Typography, Button } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import { loadMedia } from "../../../lib/index";

import { Image, IconButtonHdc } from "../../../components/index";
import placeholder from "../../../assets/images/upload.png";

export default function ModalImage(props) {
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState({});

  useEffect(() => {
    props.image
      ? loadMedia(props.image, setImage)
      : setImage({ src: placeholder }) // if no image available, use placeholder
  }, [props.image]);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const useStyles = makeStyles({
    modal: {
      margin: 0,
      padding: 0,
      heigth: "min-content",

      "& .MuiPaper-elevation24": {
        boxShadow: "none",
      },
    },

    closeButton: {
      position: "absolute",
      top: 4,
      right: 4,
    },

    title: {
      margin: 20,

      fontSize: 14,
      fontWeight: 600,
      lineHeight: "28px",
    },

    modalImage: {
      width: "auto",
      height: "80vh",
    },
  });

  const classes = useStyles();

  return (
    <Box>
      <Button onClick={handleClickOpen}>
        <Image
          certificate
          height={props.height}
          width={props.width}
          src={image.src}
        />
      </Button>
      <Dialog
        maxWidth="md"
        open={open}
        onClose={handleClose}
        className={classes.modal}
      >
        <IconButtonHdc onClick={handleClose} className={classes.closeButton}>
          <Close />
        </IconButtonHdc>
        <Typography className={classes.title}>{props.title}</Typography>
        <Image
          certificate
          className={classes.modalImage}
          src={image.src}
        />
      </Dialog>
    </Box>
  );
}

ModalImage.propTypes = {
  title: PropTypes.string,
  src: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};
