import PropTypes from "prop-types";
import { Box, makeStyles } from "@material-ui/core";

import { Btn } from "../../components/index";
import { theme } from "../../lib/index";

export default function EscOrSaveButtons(props) {
  const useStyles = makeStyles({
    box: {
      position: `${props.modal ? "unset" : "fixed"}`,
      right: `${props.modal ? "unset" : 0}`,
      bottom: `${props.modal ? "unset" : props.disabled ? "-70px" : "0"}`,
      padding: `${props.modal ? "unset" : "10px 20px"}`,
      display: "flex",
      margin: 0,
      width: "100%",
      background: `${props.modal ? "unset" : theme.palette.white + "CC"}`,
      boxShadow: `${props.modal ? "unset" : theme.palette.midgrey + " 0 0 10px"}`,

      justifyContent: "flex-end",
      alignSelf: "flex-end",
      gap: 10,

      transition: "bottom 200ms ease-out"
    },
  });

  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <Btn
        disabled={!props.modal && props.disabled}
        flavor="trans"
        padding="lg"
        onClick={props.handleClose}
        type={props.typeClose}
      >
        {props.modal ? "Abbrechen" : "Änderungen verwerfen"}
      </Btn>
      <Btn
        disabled={props.disabled}
        flavor="black"
        padding="lg"
        onClick={props.handleSave}
        type={props.typeSave}
        startIcon={props.submitStartIcon}
      >
        Speichern
      </Btn>
    </Box>
  );
}

EscOrSaveButtons.propTypes = {
  modal: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
  disabled: PropTypes.any,
  submitStartIcon: PropTypes.node,
  typeClose: PropTypes.string,
  typeSave: PropTypes.string,
};
