import { Navigate, Route, Routes } from "react-router-dom";
import {
  Offers,
  Profile,
  Ratings,
  Downloads,
  Calendar,
  Coisar,
  Personal,
  Focus,
  Certificate,
  Media,
  Support,
  Billing,
  SocialMedia,
  Vision,
  Login,
  Logout,
} from "../views/index";
import { Layout } from "./layout/index";

/**
 * Defines if a silent login should be tried
 */
export const AUTOMATIC_LOGIN = true;

export function App() {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Login />} >
          <Route index element={<Navigate to="/profile/personal" replace />} />
          <Route path="profile" element={<Profile/>} >
            <Route path="personal" element={<Personal/>} />
            <Route path="focus" element={<Focus/>} />
            <Route path="vision" element={<Vision/>} />
            <Route path="certificate" element={<Certificate/>} />
            <Route path="media" element={<Media/>} />
            <Route path="calendar" element={<Calendar/>} />
            <Route path="billing" element={<Billing/>} />
            <Route path="socialmedia" element={<SocialMedia />} />
          </Route>
          <Route path="offers" element={<Offers/>} />
          <Route path="ratings" element={<Ratings/>} />
          <Route path="downloads" element={<Downloads/>} />
          <Route path="logout" element={<Logout/>} />

          <Route
            // 404 Route
            path="*"
            element={<Navigate to="/profile/personal" replace />}
          />
        </Route>

        <Route path="support" element={<Support/>} />
      </Routes>
    </Layout>
  );
}
