import {
  mapStateKeyToBackendKey,
  socialMap,
  usePatchUser,
  useUser,
} from "../../../lib/index";

export function useHandleDeleteSocialMedia() {
  const user = useUser()
  const { mutateAsync: patchUser } = usePatchUser()

  return (plattform) => {
    const userSocialMedia = user.data.content.reader_profile_description_socials
    const plattformKey = mapStateKeyToBackendKey(plattform, socialMap)
    const readerProfileDescriptionSocials = {
      ...userSocialMedia,
      [plattformKey]: '',
    }

    const patchedData = {
      reader_profile_description_socials: readerProfileDescriptionSocials,
    };

    return patchUser(patchedData);
  }
}
