import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PropTypes from 'prop-types';
import { Box } from '@mui/system';
import { makeStyles, Container, Typography, Snackbar } from '@material-ui/core';
import { Stack } from '@mui/material';
import { Edit, ShoppingCart, Error } from '@material-ui/icons';
import { Btn, IconButtonHdc, ModalHdc, Spinner } from '../../components/index';
import {
  theme,
  constants as c,
  useUser,
  dateForApi,
  dateForApp,
} from '../../lib/index';
import { useOrderCoisarChartMutation } from './useOrderCoisarChartMutation'

const useStyles = makeStyles({
  button: {
    display: 'flex',

    justifyContent: 'center'
  },
  title: {
    margin: '0 auto',
    paddingTop: 50,
  },

  container: {
    marginTop: 50,
    marginBottom: 65,
    width: 800,
  },

  text: {
    ...theme.typography.p,
    maxWidth: '80%',
    textAlign: 'center',
  },

  innerModalContainer: {
    display: 'flex',

    flexDirection: 'column',
    gap: 20,
  },

  buttonBox: {
    display: 'flex',
    paddingTop: 20,

    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
  },

  link: {
    textDecoration: 'none',
  },

  customerData: {
    margin: '20px auto',
    padding: '20px',
    maxWidth: '80%',
    border: `1px solid ${theme.palette.darkgrey}`,

    justifyContent: 'space-between',

    '& h6': {
      paddingBottom: 10,
    },
  },
});

export default function ModalAddReading({
  buttonName,
  buttonSize,
  chosenClient,
  handleEditCustomer,
  noMargin,
  offeredReadingTypes,
}) {
  const user = useUser()
  const { content } = user.data
  const { mutateAsync: orderCoisarChart } = useOrderCoisarChartMutation()
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmSaveOpen, setConfirmSaveOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handleClickOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const handlePurchase = async () => {
    setLoading(true);
    setErrorMessage(null)

    try {
      await orderCoisarChart({
        name: chosenClient.name,
        dateOfBirth: dateForApi(chosenClient.birthdate),
        timeOfBirth: chosenClient.birthtime,
        locationOfBirth: chosenClient.birthplace,
        timezoneOfBirth: chosenClient.birthtimezone,
        readerId: content.reader_id,
        clientUuid: chosenClient.id,
      })
      setModalOpen(false);
    } catch (e) {
      setErrorMessage(e.message);
    }

    setLoading(false)
  };

  const handleError = () => {
    setLoading(false);
    setModalOpen(false);
  }

  const classes = useStyles();
  let offeredReadings = [];

  offeredReadingTypes?.forEach((e) => {
    offeredReadings.push({ choice: e });
  });

  return (
    <ModalHdc
      buttonName={buttonName}
      buttonSize={buttonSize}
      buttonType={c.BLACK}
      className={classes.button}
      fullWidth
      handleClickOpen={handleClickOpen}
      handleClose={handleClose}
      noMargin={noMargin}
      open={modalOpen}
    >
      {loading && (
        <Container>
          <Spinner />
        </Container>
      )}

      {errorMessage && (
        <Stack justifyContent="center" alignItems="center" gap="30px">
          <Stack flexDirection="row" gap="20px" justifyContent="center" alignItems="center">
            <Error color="error" fontSize="large"/>
            <Typography variant="h3" color="error">Fehler beim Anfordern des Charts</Typography>
          </Stack>
          <Typography variant="h6">Fehlermeldung: {errorMessage}</Typography>
          <Btn flavor={c.BLACK} onClick={handleError}>zurück</Btn>
        </Stack>
      )}

      {!loading && !errorMessage && (
        <>
          <Typography variant='h3' className={classes.title}>
            Chart anfordern
          </Typography>
          <Container className={classes.container}>
            <Stack direction='row' spacing={3} className={classes.customerData}>
              <Box>
                <Typography variant='h6'>
                  Für folgende Person wird ein Chart angefordert:
                </Typography>
                <Typography>
                  {chosenClient.name}, geboren am{' '}
                  {dateForApp(chosenClient.birthdate)} um{' '}
                  {chosenClient.birthtime} <br /> in {chosenClient.birthplace}
                </Typography>
              </Box>
              <Stack direction='row' spacing={1}>
                <IconButtonHdc onClick={() => handleEditCustomer(chosenClient)}>
                  <Edit />
                </IconButtonHdc>
              </Stack>
            </Stack>
            <Box className={classes.buttonBox}>
              {chosenClient.coisarStatus === 'received' ? (
                <>
                  <Typography variant='h6'>
                    Chart erfolgreich angefordert
                  </Typography>
                  <CopyToClipboard text={chosenClient.coisarPw}>
                    <Btn padding={c.SM} onClick={() => setConfirmSaveOpen(true)}>
                      Passwort: {chosenClient.coisarPw}
                    </Btn>
                  </CopyToClipboard>
                  <Snackbar
                    open={confirmSaveOpen}
                    autoHideDuration={1500}
                    message='Das Passwort wurde in die Zwischenablage kopiert.'
                    onClose={() => setConfirmSaveOpen(false)}
                  />
                  <Btn
                    flavor={c.BLACK}
                    padding={c.LG}
                    href={chosenClient.coisarLink}
                  >
                    Coisar starten
                  </Btn>
                </>
              ) : (
                <>
                  <Typography className={classes.text}>
                    Mit dem Anfordern des Charts wird die Coisar-Gebühr für die
                    Bereitstellung fällig. Die Gebühr beträgt 49€.
                  </Typography>
                  <Btn
                    startIcon={<ShoppingCart />}
                    flavor={c.BLACK}
                    onClick={handlePurchase}
                  >
                    Chart anfordern
                  </Btn>
                </>
              )}
            </Box>
          </Container>
        </>
      )}
    </ModalHdc>
  );
}

ModalAddReading.propTypes = {
  buttonName: PropTypes.string,
  buttonSize: PropTypes.string,
  handleAddReading: PropTypes.func,
  noMargin: PropTypes.bool,
  row: PropTypes.object,
};
