import createStore from 'zustand'
import { encryptStorage } from './encryptedStorage.js'

const storedUsername = encryptStorage.getItem('username');
const storedPassword = encryptStorage.getItem('password');

export const useEncryptStorage = createStore((set, get) => ({
  username: storedUsername || null,
  password: storedPassword || null,

  setCredentials({ username, password }) {
    encryptStorage.setItem('username', username)
    encryptStorage.setItem('password', password)
    return set({ username, password })
  },

  getCredentials() {
    const { username, password } = get()
    return { username, password }
  }
}))
