export function formatRatingsForApi(ratings) {
  return ratings.map(rating => {
    const date = rating.date.split(".").reverse().join("");
    return {
      reader_rating_uuid: rating.id,
      reader_rating_date: date,
      reader_rating_photo: rating.src ? rating.src : 499,
      reader_rating_stars: "" + rating.stars,
      reader_rating_text: rating.text,
      reader_rating_name: rating.title,
      reader_rating_link: rating.link,
    };
  })
}
