
export const mapBackendKeyToStateKey = (key, map) => {
  const foundBackendKey = map.find((entry) => {
    if (entry.backendKey === key) return entry;
    else return undefined;
  })
  const foundMappedKey = map.find((entry) => {
    if (entry.mappedKey === key) return entry;
    else return undefined;
  })
  switch (true) {
  case foundBackendKey !== undefined:
    return foundBackendKey.mappedKey;
  
  case foundMappedKey !== undefined:
    return foundMappedKey.mappedKey;
  
  default:
    return "unknown"
  }
};


export const mapStateKeyToBackendKey = (key, map) => {
  const foundBackendKey = map.find((entry) => {
    if (entry.backendKey === key) return entry;
    else return undefined;
  })
  const foundMappedKey = map.find((entry) => {
    if (entry.mappedKey === key) return entry;
    else return undefined;
  })

  switch (true) {
  case foundBackendKey !== undefined:
    return foundBackendKey.backendKey;

  case foundMappedKey !== undefined:
    return foundMappedKey.backendKey;

  default:
    return "unknown"
  }
};
