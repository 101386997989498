import { makeStyles } from "@material-ui/core";
import { theme } from "../../lib/index"
import { NavLink, useLocation } from "react-router-dom";

const useStyles = makeStyles({
  linkbox: {
    position: "relative",

    display: "flex",
    marginBottom: 25,

    justifyContent: "flex-start",
    alignItems: "center",
    gap: 0,

    color: theme.palette.white,
    textDecoration: "none",

    transition: `${theme.transitions.create(["color"], { duration: theme.transitions.duration.standard, })}`,

    "&:hover": {
      color: theme.palette.midgrey,
    },

    "&.active": {
      color: theme.palette.hdc,
    },

    "& .linkbox": {
      "&__link": {
        ...theme.typography.menulink,
        display: "block",
        marginLeft: 0,
        paddingLeft: "14px",
  
        color: "currentColor",
  
        transition: `${theme.transitions.create(["margin-left"], {
          duration: theme.transitions.duration.standard,
        })}`,
  
        cursor: "pointer",
  
        "&:hover": {
          marginLeft: 5,
        },
      },
  
      "__icon": {
        display: "block",
        marginTop: "5px",
  
        color: "currentColor",
      },
    },
  },
});

export default function LinkListItem({ name, path, icon, moveToBottom, href }) {
  const classes = useStyles();

  const loc = useLocation();
  let pathCheck =  loc.pathname.includes("profil");

  return (
    <NavLink
      style={moveToBottom && { bottom: "0", position: "absolute" }}
      name={name}
      href={href}
      to={path}
      className={name.includes('Profil') && pathCheck
        ? `${classes.linkbox} active` 
        : classes.linkbox} 
    >
      <span className="linkbox__icon">
        {icon}
      </span>

      <span className="linkbox__link">
        {name}
      </span>
    </NavLink>
  )
}
