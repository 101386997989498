import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Checkbox,
  Container,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";

import { theme } from "../../lib/index";

const createUseStyles = ({ constrainedSize, meta }) => makeStyles({
  container: {
    marginBottom: 35,
    padding: 0,
    maxWidth: constrainedSize ? "50%" : "100%",
  },

  label: {
    ...theme.typography.label,
    marginBottom: 9,

    color: theme.palette.darkgrey,
  },

  select: {
    width: "100%",
    minWidth: 200,

    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",

    border: `${
      meta.touched ? meta.error && "2px solid " + theme.palette.alert.main : "none"
    }`,
    background: theme.palette.backgroundgrey,

    "& .MuiFilledInput-input": {
      padding: "12px 50px 12px 15px",
    },

    "&:before, &:after, &.MuiFilledInput-underline:hover:before": {
      border: "none",
    },

    "&:hover": {
      background: theme.palette.lightgrey,
    },

    "& .MuiSelect-select:focus": {
      background: theme.palette.backgroundgrey,
    },
  },

  dropdownText: {
    ...theme.typography.p,
    margin: 0,

    color: theme.palette.placeholdergrey,
  },

  errorMessage: {
    color: theme.palette.alert.main,
  },
});

export default function DropDownFF({ constrainedSize, input, meta, ...rest }) {
  const [selected, setSelected] = useState(rest.preSelected || input.value || []);

  useEffect(() => {
    if (rest.preSelected) {
      setSelected(rest.preSelected);
    } else if (input.value) {
      setSelected(input.value)
    }
  }, [rest.preSelected, input.value]);

  const useStyles = createUseStyles({ constrainedSize, meta })
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <InputLabel className={classes.label}>
        {rest.label}
        {rest.required && " *"}
      </InputLabel>
      <Select
        className={classes.select}
        displayEmpty
        inputProps={rest.inputProps}
        multiple={rest.multipleChoice}
        placeholder={rest.placeholder}
        value={selected || ""}
        variant="filled"
        onChange={(e) => {
          if (rest.multipleChoice) {
            setSelected(
              typeof e.target.value === "string"
                ? e.target.value.split(",")
                : e.target.value
            );
            input.onChange(e.target.value);
          } else {
            setSelected(e.target.value + "")
            input.onChange(e.target.value + "")
          }
        }}
        renderValue={(selected) => {
          if (rest.preSelected && !selected) {
            return rest.preSelected
          } else if (!rest.preSelected && selected.length < 1) {
            return (
              <Typography className={classes.dropdownText}>
                {rest.placeholder}
              </Typography>
            )
          } else if (rest.multipleChoice) {
            return selected.join(", ");
          }
          return selected
        }}
      >
        <MenuItem disabled value="">
          <Typography className={classes.dropdownText}>
            {rest.placeholder}
          </Typography>
        </MenuItem>
        {rest.items?.map((item) => (
          <MenuItem key={item.choice} value={item.choice}>
            <Checkbox
              color="default"
              className={classes.checkbox}
              checked={selected.indexOf(item.choice) > -1}
            />
            {item.choice}
          </MenuItem>
        ))}
      </Select>
      {meta.touched && meta.error && (
        <span className={classes.errorMessage}>{meta.error}</span>
      )}
    </Container>
  );
}

DropDownFF.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  multipleChoice: PropTypes.bool,
};
