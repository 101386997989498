import { makeStyles } from "@material-ui/core";
import { theme } from '../../../lib/index.js'

export const useStyles = makeStyles({
  first: {
    paddingRight: 50,
  },

  second: {
    paddingRight: 25,

    "& .dropdowns": {
      margin: "0 auto",
      maxWidth: 1280,
    }
  },

  flex: {
    display: "flex",

    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
  },

  image: {
    marginBottom: 35,
    width: "100%",
    maxWidth: 248,
    maxHeight: 248,

    aspectRatio: "1 / 1",
  },

  skeletonImage: {
    marginBottom: 35,
    width: "100%",

    borderRadius: "50%",

    aspectRatio: "1 / 1",
  },

  headline: {
    margin: "0 auto 23px",
    maxWidth: 1280,
  },

  subSubline: {
    ...theme.typography.pSmall,
    marginTop: -30,
    marginBottom: 10,

    color: theme.palette.placeholdergrey,
    textAlign: "center",
  },

  snackbar: {
    "& .MuiSnackbarContent-root": {
      minWidth: "fit-content",
    },
  },
});
