import { Box, Typography } from "@material-ui/core";
import {
  Btn,
  Image,
  UploadButton,
} from "../../../components/index";
import { constants as c } from '../../../lib/index.js'
import PauseButton from "./PauseButton";
import { useStyles } from './useStyles.js'

export function ProfileImage({
  userImage,
  handleUserImage,
  handleDeleteUserImage,
  inactive,
}) {
  const classes = useStyles();

  return (
    <>
      <Box maxWidth="sm" className={classes.flex}>
        {userImage.src 
          ? <Image circle className={classes.image} src={userImage.src} />
          : <Box className={classes.skeletonImage} />
        }
        <Typography className={classes.subSubline}>
          .jpg-, .jpeg, .png oder .webp-Format, max. 1MB
        </Typography>
        <UploadButton image handleUploadedFile={handleUserImage}>
          Bild hochladen
        </UploadButton>
        <Btn
          onClick={handleDeleteUserImage}
          padding={c.LG}
          type={c.TRANSPARENT}
        >
          Bild löschen
        </Btn>
      </Box>
      <PauseButton disabled={inactive} />
    </>
  )
}
