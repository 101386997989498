import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEncryptStorage } from '../encryption/index'
import { patch } from '../http/index'
import { createUserQueryKey } from './query-key-factory.js'

export function usePatchUser() {
  const credentials = useEncryptStorage(store => store.getCredentials())
  const userQueryKey = createUserQueryKey(credentials)
  const queryClient = useQueryClient()
  const mutationFn = userData => patch('users/me', { acf: userData })

  return useMutation(mutationFn, {
    // Optimistically update the values we have changed in the cache
    onMutate: async (variables) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(userQueryKey)

      // Snapshot the previous value
      const previousUserData = queryClient.getQueryData(userQueryKey)

      // Optimistically update to the new value
      queryClient.setQueryData(userQueryKey, () => ({
        ...previousUserData,
        data: {
          ...previousUserData.data,
          acf: {
            ...previousUserData.data.acf,
            ...variables,
          },
        },
      }))

      // Needed in onError
      const context = { previousUserData, userQueryKey }
      return context
    },
    // Use response to set actual data
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: userQueryKey })
    },
    // Roll back optimistic update
    onError: (event, _, context) => {
      queryClient.setQueryData(
        context.userQueryKey,
        context.previousUserData
      )
    },
  })
}
