import { mapBackendKeyToStateKey, socialMap } from '../../../lib/index'

export function computeInactiveSocialMedia(userSocialMedia) {
  return Object.entries(userSocialMedia)
    .filter(([, value]) => !value)
    .map(([key, value]) => ({
      plattform: mapBackendKeyToStateKey(key, socialMap),
      link: value,
    }))
}
