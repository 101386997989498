import { Field } from "react-final-form";
import { InputTextfieldFF } from "../../../components/index";
import { validation as val } from "../../../lib/index";

export function IntroductionField() {
  return (
    <Field
      characterLimit={2000}
      component={InputTextfieldFF}
      counter
      fullWidth
      label="Kurzvorstellung"
      multiline
      name="introduction"
      placeholder="Stell dich kurz vor (max. 2000 Zeichen)"
      required
      rows={15}
      type="text"
      validate={val.composeValidators(
        val.mustBeText,
        val.required,
        val.limitCharacters2000
      )}
    />
  )
}
