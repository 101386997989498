
export const languageMap = [
  {
    backendKey: "reader_profile_description_languages_german",
    mappedKey: "Deutsch",
  },
  {
    backendKey: "reader_profile_description_languages_english",
    mappedKey: "Englisch",
  },
  {
    backendKey: "reader_profile_description_languages_spanish",
    mappedKey: "Spanisch",
  },
  {
    backendKey: "reader_profile_description_languages_portuguese",
    mappedKey: "Portugiesisch",
  },
  {
    backendKey: "reader_profile_description_languages_polish",
    mappedKey: "Polnisch",
  },
];

export const socialMap = [
  {
    backendKey: "reader_profile_description_socials_facebook",
    mappedKey: "Facebook",
  },
  {
    backendKey: "reader_profile_description_socials_instagram",
    mappedKey: "Instagram",
  },
  {
    backendKey: "reader_profile_description_socials_twitter",
    mappedKey: "Twitter",
  },
  {
    backendKey: "reader_profile_description_socials_youtube",
    mappedKey: "Youtube",
  },
  {
    backendKey: "reader_profile_description_socials_linkedin",
    mappedKey: "LinkedIn",
  },
];

export const typeOfReadingMap = [
  {
    backendKey: "reader_offers_type_standard",
    mappedKey: "Standard Reading",
  },
  {
    backendKey: "reader_offers_type_business",
    mappedKey: "Business Reading",
  },
  {
    backendKey: "reader_offers_type_career",
    mappedKey: "Karriere Reading",
  },
  {
    backendKey: "reader_offers_type_family",
    mappedKey: "Familien Reading",
  },
  {
    backendKey: "reader_offers_type_children",
    mappedKey: "Kinder Reading",
  },
  {
    backendKey: "reader_offers_type_partner",
    mappedKey: "Partner Reading",
  },
  {
    backendKey: "reader_offers_type_transit",
    mappedKey: "Transit Report",
  },
];
