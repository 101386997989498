import PropTypes from "prop-types";
import { Checkbox, FormControlLabel, makeStyles } from "@material-ui/core";
import { Close, Check } from "@material-ui/icons";

import { theme } from "../../lib/index";

export default function ToggleButtonFF({ input, ...rest }) {

  const useStyles = makeStyles({
    checkbox: {
      margin: "10px 10px 10px 0",
      padding: "15px 24px 15px 24px",
      border: `${input.checked ? "1px solid transparent" : "1px solid black"}`,

      borderRadius: 0,
      background: `${input.checked ? theme.palette.hdc : theme.palette.white}`,

      transition: `${theme.transitions.create(["border", "background"], {
        duration: theme.transitions.duration.long,
      })}`,

      "& .MuiFormControlLabel-label": {
        ...theme.typography.pToggleSwitch,
        color: theme.palette.black,
      },

      "&:hover": {
        background: `${theme.palette.lightgrey}`,
      },

      "& .MuiButtonBase-root": {
        padding: "0 10px 0 0",
      },

      "& .MuiIconButton-label": {
        color: theme.palette.black,

        transform: "scale(1.2)",
      },
    },
  });
  const classes = useStyles();

  return (
    <FormControlLabel
      className={classes.checkbox}
      control={
        <Checkbox
          checked={input.checked}
          checkedIcon={<Check />}
          color="primary"
          disabled={rest.disabled}
          icon={<Close />}
          name={input.name}
          onClick={() => {
            input.onChange(!input.checked);
          }}
        />
      }
      label={rest.label}
    />
  );
}

ToggleButtonFF.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
};
