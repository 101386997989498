import { makeStyles, Button, Box, Snackbar, Typography } from "@material-ui/core";
import { Visibility, VisibilityOff, OpenInNew, Link } from "@material-ui/icons";
import { useState } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { theme, usePatchUser, useUser } from "../../../lib/index";

const useStyles = ({ pauseReadings }) => makeStyles({
  wrapper: {
    textAlign: "center",
  },
  button: {
    ...theme.typography.pToggleButton,

    margin: "100px 0 0",
    padding: "15px 24px",
    width: "100%",
    border: `${pauseReadings ? `1px solid ${theme.palette.black}` : "1px solid transparent"}`,

    borderRadius: 0,
    background: `${pauseReadings ? theme.palette.white : theme.palette.hdc }`,

    color: theme.palette.black,

    transition: `${theme.transitions.create(['border', 'background'], {
      duration: theme.transitions.duration.long,
    })}`,

    [theme.breakpoints.down('md')]: {
      margin: "50px 0 0",
    },

    "&:hover": {
      background: `${theme.palette.lightgrey}`,
    },

    "&.Mui-disabled": {
      border: `${pauseReadings ? "1px solid lightgrey" : "1px solid transparent"}`,
      background: `${pauseReadings ? theme.palette.hdc + "50" : theme.palette.lightgrey + "50"}`,
    },
  },
  pauseText: {
    margin: "20px 0",

    textAlign: "center",
  },
  linkbox: {
    marginBottom: -200,
    padding: "10px 20px",

    opacity: pauseReadings ? 0 : 1,

    transform: pauseReadings ? "translateY(20px)" : "translateY(0)",
    transition: "opacity 500ms ease-in-out, transform 500ms ease-in-out",

    "& .link": {
      ...theme.typography.pToggleButton,

      margin: "10px auto",
      padding: "10px 20px",
      width: "100%",
      borderLeft: "4px solid lightgrey",

      background: theme.palette.backgroundgrey,

      justifyContent: "start",

      transition: "borderLeft 500ms ease-in-out",

      pointerEvents: pauseReadings ? "none" : "auto",

      "& .MuiButton-label": {
        gap: 10,
      },

      "&:hover": {
        background: `${theme.palette.lightgrey}`,
      }
    }
  },
})();

export default function PauseButton({ disabled: disabledProp }) {
  const { mutateAsync: patchUser, isLoading: isPatchingUser } = usePatchUser();
  const disabled = disabledProp || isPatchingUser
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { user, content } = useUser().data;
  const { userId: readerId } = user;
  const { reader_calendar_pause: pauseReadings } = content;

  // handle pause switch
  const handlePauseReadings = async () => {
    const patchedData = { reader_calendar_pause: !pauseReadings }
    await patchUser(patchedData)
    setSnackbarOpen(true)
  };

  const classes = useStyles({ pauseReadings });

  return (
    <Box className={classes.wrapper}>
      <Button
        {...{ disabled }}
        className={classes.button}
        onClick={() => {
          console.log('> onClick');
          handlePauseReadings()
        }}
        startIcon={
          pauseReadings
            ? <Visibility />
            : <VisibilityOff />
        }
      >
        {
          pauseReadings
            ? "Profil anzeigen"
            : "Profil verbergen"
        }
      </Button>
      { disabled 
        ? <Typography className={classes.pauseText}>
          Dein Profil wurde noch nicht aktiviert. Bitte wende dich an den HDC-Support.
        </Typography>
        :  <Typography className={classes.pauseText}>
          { pauseReadings
            ? "Dein Profil ist derzeit nicht in der HDC Reader Übersicht zu finden."
            : "Dein Profil wird auf der offiziellen HDC-Website angezeigt."
          }
        </Typography>
      } 
      <Box className={classes.linkbox}>
        <Button
          {...{ disabled }}
          className="link"
          startIcon={<OpenInNew />}
          href={`https://humandesign24.de/readers-directory/?coisar_reader=${readerId}`}
          target="_blank"
        >
            zum Profil
        </Button>
        <CopyToClipboard text={`https://humandesign24.de/readers-directory/?coisar_reader=${readerId}`}>
          <Button
            {...{ disabled }}
            className="link"
            startIcon={<Link />}
            onClick={() => setSnackbarOpen(true)}
          >
            Link kopieren
          </Button>
        </CopyToClipboard>
        <Snackbar
          //anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
          open={snackbarOpen}
          autoHideDuration={1500}
          message="Der Link wurde in die Zwischenablage kopiert."
          onClose={() => setSnackbarOpen(false)}
        />
      </Box>
    </Box>
  )
}
