import { useState } from "react";
import PropTypes from "prop-types";
import { Box, makeStyles, Typography, Container } from "@material-ui/core";
import { Star } from "@material-ui/icons";

import { theme } from "../../lib/index";

export default function StarRating({ input, meta, ...rest }) {
  const currentValue = input.value;
  const [hoverValue, setHoverValue] = useState(undefined);
  const stars = Array(5).fill(0);

  let count = hoverValue || currentValue;

  const handleMouseOver = (newHoverValue) => {
    setHoverValue(newHoverValue);
  };

  const handleMouseLeave = () => {
    setHoverValue(undefined);
  };

  const useStyles = makeStyles({
    container: {
      marginBottom: `${rest.noMargin ? 0 : "35px"}`,
      padding: 0,
    },

    box: {
      display: "flex",
      padding: `${rest.isInput ? "12px 15px" : 0}`,
      width: `${rest.isInput ? "initial" : "fit-content"}`,
      border: `${
        meta.touched && meta.error
          ? "1px solid " + theme.palette.alert.main
          : "1px solid transparent"
      }`,

      flexWrap: "nowrap",
      alignItems: "center",

      background: `${rest.isInput ? theme.palette.backgroundgrey : 0}`,

      "&:hover": {
        borderColor: `${theme.palette.placeholdergrey}50`,
      },
    },

    label: {
      ...theme.typography.label,
      display: `${rest.isInput ? "block" : "none"}`,
      paddingBottom: "9px",

      color: theme.palette.darkgrey,
    },

    star: {
      marginRight: 10,

      fontSize: 20,

      transition: `${theme.transitions.create(["stroke", "fill"], {
        duration: theme.transitions.duration.standard,
      })}`,

      cursor: `${rest.fixedStars ? "initial" : "pointer"}`,
      strokeWidth: 1,
      strokeLinejoin: "round",
    },

    outlined: {
      fill: "transparent",
      stroke: theme.palette.midgrey,
    },

    filled: {
      fill: theme.palette.hdc,
      stroke: theme.palette.hdc,
    },

    text: {
      ...theme.typography.p,
      marginLeft: 2,
      width: 70,

      color: theme.palette.darkgrey,
    },

    errorMessage: {
      color: theme.palette.alert.main,
    },
  });

  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Typography className={classes.label}>
        Anzahl der Sterne{rest.required && " *"}
      </Typography>
      <Box className={classes.box}>
        {stars.map((_, index) => {
          return (
            <Star
              className={`${classes.star} ${
                rest.fixedStars > 0
                  ? rest.fixedStars > index
                    ? classes.filled
                    : classes.outlined
                  : (hoverValue || currentValue) > index
                    ? classes.filled
                    : classes.outlined
              } ${rest.className && rest.className}`}
              key={index}
              onClick={() => {
                // setCurrentValue(index + 1);
                input.onChange(index + 1);
              }}
              onMouseOver={() => handleMouseOver(index + 1)}
              onMouseLeave={handleMouseLeave}
            />
          );
        })}
        {!rest.fixedStars && (
          <Typography className={classes.text}>
            {count ? count : "0"}
            {count !== 1 ? " Sterne" : " Stern"}
          </Typography>
        )}
      </Box>
      {meta.touched && meta.error && (
        <span className={classes.errorMessage}>{meta.error}</span>
      )}
    </Container>
  );
}

StarRating.propTypes = {
  fixedStars: PropTypes.number,
  isInput: PropTypes.bool,
  noMargin: PropTypes.bool,
};
