import {
  makeStyles, Box
} from "@material-ui/core";
import { theme } from "../../lib/index";

const useStyles = makeStyles({
  buybutton: {
    height: 65,
    width: 175,
    display: 'flex',
    alignItems: 'center',

    '&.shopify-buy-frame--product': {
      display: 'flex'
    },

    '& .shopify-buy__product > .shopify-buy__product__price': {
      display: 'none'
    },
  
    '& .shopify-buy__product > .shopify-buy__btn-wrapper > .shopify-buy__btn': {
      margin: '0',
      padding: '15px 24px',
      width: 'fit-content',
      border: 'none',
  
      background: theme.palette.black,
      borderRadius: 0,
  
      color: theme.palette.hdc,
      fontSize: 15,
      fontWeight: 600,
      textTransform: 'uppercase',
      whiteSpace: "nowrap"
    },
  
    '& .shopify-buy__product > .shopify-buy__btn-wrapper > .shopify-buy__btn:hover': {
      opacity: 0.75,
      background: theme.palette.black,
  
      cursor: 'pointer',
    }
  }
});

function ShopifyBuyInit(shopifyId) {
  // eslint-disable-next-line no-undef
  const client = ShopifyBuy.buildClient({
    domain: 'humandesignclub.myshopify.com',
    storefrontAccessToken: '7baca2730b03321f5d02cbcf5daaa2b8',
  }); // eslint-disable-next-line no-undef
  ShopifyBuy.UI.onReady(client).then((ui) => {
    ui.createComponent('product', {
      id: shopifyId,
      node: document.getElementById(
        'hdc-readerselfservice-pay-coisar-ip'
      ),
      moneyFormat: '%E2%82%AC%7B%7Bamount_with_comma_separator%7D%7D',
      options: {
        product: {
          iframe: false,
          buttonDestination: 'checkout',
          layout: 'horizontal',
          styles: {},
          contents: {
            img: false,
            title: false,
            price: false,
          },
          text: {
            button: 'coisar bezahlen',
          },
          googleFonts: ['Poppins'],
        },
        productSet: {
          styles: {},
        },
        option: {},
        cart: {
          styles: {},
          text: {
            total: 'Subtotal',
            button: 'Checkout',
          },
          popup: true,
          googleFonts: ['Open Sans'],
        },
      },
    });
  });
}

function loadScript(scriptURL, shopifyId) {
  const script = document.createElement('script');
  script.async = true;
  script.src = scriptURL;
  (
    document.getElementsByTagName('head')[0]
    || document.getElementsByTagName('body')[0]
  ).appendChild(script);
  script.onload = () => ShopifyBuyInit(shopifyId);
}

function buybutton(shopifyId) {
  const scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
  if (window.ShopifyBuy) {
    if (window.ShopifyBuy.UI) {
      ShopifyBuyInit(shopifyId);
    } else {
      loadScript(scriptURL, shopifyId);
    }
  } else {
    loadScript(scriptURL, shopifyId);
  }
}

export default function ShopifyBuyButton({ shopifyId }) {
  const classes = useStyles();

  // create buy button, but only if it has not been created yet
  if (!document.getElementById('hdc-readerselfservice-pay-coisar-ip')?.children ) buybutton(shopifyId);

  return (
    <Box id='hdc-readerselfservice-pay-coisar-ip' className={classes.buybutton}></Box>
  )
}
