import { Box, makeStyles } from '@material-ui/core';
import InnerGrid from './InnerGrid';
import Navbar from './Navbar';
import { theme, useUser } from '../../lib/index';

/**
 * Defines if a silent login should be tried
 */
export const AUTOMATIC_LOGIN = true;

export default function Layout({ children }) {
  const user = useUser()
  const useStyles = makeStyles({
    container: {
      display: 'flex',
      paddingLeft: theme.menuWidthDesktop,
      height: '100%',
      minHeight: '100vh',
    },
  });
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {user.data && <Navbar />}
      <InnerGrid>{children}</InnerGrid>
    </Box>
  );
}
