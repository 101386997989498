import { useMemo, useState } from "react";
import { Field, Form } from "react-final-form";
import { makeStyles, Grid, Snackbar, Typography, Box } from "@material-ui/core";
import {
  Headline,
  ToggleButtonFF,
  InputTextfieldFF,
  EscOrSaveButtons,
} from "../../../components/index";
import {
  theme,
  validation as val,
  usePatchUser,
  useUser,
} from "../../../lib/index";

const useStyles = makeStyles({
  form__label: { 
    ...theme.typography.label
  }
});

function useInitialValues() {
  const user = useUser();
  const { content } = user.data;

  const {
    reader_profile_description_bank: bank,
    reader_profile_ust: ust,
  } = content;

  const iban = bank.reader_profile_description_bank_iban;
  const bic = bank.reader_profile_description_bank_bic;
  const ust_idnr = ust.reader_profile_ust_idnr;
  const kleinunternehmer = ust.reader_profile_ust_kleinunternehmer;

  return useMemo(
    () => ({ iban, bic, ust_idnr, kleinunternehmer }),
    [iban, bic, ust_idnr, kleinunternehmer]
  )
}

function formatFormValues(values, initialValues) {
  // do we need to save a timestamp?
  const timestamp = values.kleinunternehmer === initialValues.kleinunternehmer 
    ? undefined
    : +new Date()

  const bank = {
    reader_profile_description_bank_iban: values.iban || "",
    reader_profile_description_bank_bic: values.bic || ""
  }

  let ust = {
    reader_profile_ust_idnr: values.ust_idnr || "",
    reader_profile_ust_kleinunternehmer: values.kleinunternehmer || false,
  }

  if (timestamp) ust = {
    ...ust,
    reader_profile_ust_kleinunternehmer_change: timestamp
  }

  return {
    reader_profile_description_bank: bank,
    reader_profile_ust: ust,
  };
}

function BillingField({
  name,
  description,
  component,
  label,
  placeholder,
  type,
  validate,
}) {
  const classes = useStyles();

  return (
    <Box key={name}>
      {description && (
        <Typography className={classes.form__label} key={description}>
          {description}
        </Typography>
      )}

      <Field
        key={name}
        component={component || InputTextfieldFF}
        fullWidth
        label={label}
        name={name}
        placeholder={placeholder || ""}
        type={type || "text"} 
        validate={validate || undefined}
      />
    </Box>
  )
}

export default function Billing() {
  const { mutateAsync: patchUser } = usePatchUser()
  const [confirmSave, setConfirmSave] = useState(false);
  const classes = useStyles();
  const initialValues = useInitialValues()

  const onSubmit = async (values) => {
    const patchedData = formatFormValues(values, initialValues);
    await patchUser(patchedData);
    setConfirmSave(true);
  };
 
  return (
    <>
      <Grid item xs={12}>
        <Headline>Finanzielles</Headline>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          render={({ handleSubmit, dirty, form }) => {
            return (
              <form id="formBank" onSubmit={handleSubmit}>
                <BillingField
                  label="IBAN"
                  name="iban"
                  placeholder="zB. DE24 2742 2425 ..."
                  validate={val.validateIBAN}
                />

                <BillingField
                  label="BIC"
                  name="bic"
                  placeholder="zB. GENODEM1GLS"
                />

                <BillingField
                  label="Umsatzsteuer-ID"
                  name="ust_idnr"
                  placeholder="zB. DE398517849"
                  validate={val.mustBeUstID}
                />

                <BillingField
                  component={ToggleButtonFF}
                  description="Greift die Kleinunternehmerregelung gemäß §19 UStG?"
                  label="§19 UStG"
                  name="kleinunternehmer"
                  type="checkbox"
                />
                
                <EscOrSaveButtons
                  disabled={!dirty}
                  typeSave="submit"
                  typeClose="button"
                  handleSave={handleSubmit}
                  handleClose={form.reset}
                />
              </form>
            );
          }}
        />
      </Grid>
      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={2000}
        open={confirmSave}
        onClose={() => setConfirmSave(false)}
        message="Änderungen gespeichert"
      />
    </>
  )
}
