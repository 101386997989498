import { Field } from "react-final-form";
import { DropDownFF } from "../../../components/index";
import { validation as val } from "../../../lib/index";

export function LanguagesField() {
  return (
    <Field
      component={DropDownFF}
      constrainedSize
      items={[
        { choice: "Deutsch" },
        { choice: "Englisch" },
        { choice: "Spanisch" },
        { choice: "Portugiesisch" },
        { choice: "Polnisch" }
      ]}
      label="Sprachen"
      multipleChoice
      name="languages"
      placeholder="Welche Sprachen bietest du an?"
      // preSelected={screenState.languages}
      required
      type="select"
      validate={val.required}
    />
  )
}
