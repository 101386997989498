import {
  languageMap,
  mapStateKeyToBackendKey,
} from "../../../lib/index";

export function createOnSubmit({ patchUser, setShowSaveButton, userImageId }) {
  return async (values) => {
    // Map back state keys to backend keys
    const patchedData = {
      reader_profile_description_fullname: values.name,
      reader_profile_description_introduction: values.introduction,
      reader_profile_description_location: values.location,
      reader_profile_description_photo: userImageId,
    };

    // Remap languages
    let mappedLanguages = Object.fromEntries([
      // Better safe than sorry: Set all to false per default
      ...languageMap.map(
        (language) => [language.backendKey, false]
      ),
      ...values.languages.map(
        (entry) => [mapStateKeyToBackendKey(entry, languageMap), true]
      ),
    ]);

    patchedData.reader_profile_description_languages = mappedLanguages;
    
    // wait for this to succeed before hiding the form actions
    await patchUser(patchedData);
    setShowSaveButton(false);
  };
}
