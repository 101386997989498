import { useRef } from "react"
import PropTypes from "prop-types";
import { Dialog, Box, makeStyles } from "@material-ui/core";
import { Close, Add } from "@material-ui/icons";

import { theme, constants as c } from "../../lib/index";

import { Btn } from "../Btn/index";
import { IconButtonHdc } from "../IconButtonHdc/index";

export default function ModalHdc(props) {
  const nodeRef = useRef(null);
  const useStyles = makeStyles({
    modal: {
      "& .MuiPaper-root": {
        padding: 25,
        maxWidth: `${!props.fullWidth ? "600px" : null}`,
      },

      "& .MuiPaper-elevation24": {
        boxShadow: "none",
      },
    },

    button: {
      margin: `${props.noMargin ? "0" : "15px 0 25px 0"}`,
    },

    closeButton: {
      position: "absolute",
      top: 10,
      right: 10,
    },

    title: {
      ...theme.typography.h3,
    },
  });

  const classes = useStyles();

  return (
    <Box className={`${props.className && props.className}`}>
      <Btn
        className={classes.button}
        flavor={props.buttonType}
        onClick={props.handleClickOpen}
        padding={props.buttonSize || c.LG}
        startIcon={props.startIcon || <Add />}
      >
        {props.buttonName}
      </Btn>
      <Dialog
        fullWidth={props.fullWidth}
        maxWidth="md"
        TransitionProps={nodeRef}
        open={props.open}
        onClose={props.handleClose}
        className={classes.modal}
      >
        <IconButtonHdc
          onClick={props.handleClose}
          className={classes.closeButton}
        >
          <Close />
        </IconButtonHdc>
        {props.children}
      </Dialog>
    </Box>
  );
}

ModalHdc.propTypes = {
  children: PropTypes.any,
  buttonType: PropTypes.oneOf([
    c.BLACK,
    c.TRANSPARENT,
    c.BORDER,
    c.WHITEBORDER,
  ]),
  buttonSize: PropTypes.oneOf([c.SM, c.MD, c.LG]),
  buttonName: PropTypes.string,
  startIcon: PropTypes.any,
  handleClickOpen: PropTypes.func,
  handleClose: PropTypes.func,
  open: PropTypes.bool,
};
