import { usePatchUser } from "../../../lib/index";
import { useCertificates } from './useCertificates'

function mergeCertificateData(certificates, newCertificateData) {
  if (!document.getElementById(newCertificateData.id)) {
    return [...certificates, newCertificateData]
  }

  return certificates.map((certificate) => (
    certificate.id !== newCertificateData.id
      ? certificate
      : newCertificateData
  ))
}

export function useHandleNewCertificate() {
  const certificates = useCertificates()
  const { mutateAsync: patchUser } = usePatchUser()

  return (newCertificateData) => {
    const mergedData = mergeCertificateData(certificates, newCertificateData)
    const mappedCertificates = mergedData.map(c => ({
      reader_profile_certificates_uuid: c.id,
      reader_profile_certificates_name: c.name,
      reader_profile_certificates_description: c.description || "",
      reader_profile_certificates_image: c.image || 499,
    }));

    const patchedData = { reader_profile_certificates: mappedCertificates };
    return patchUser(patchedData);
  }
};
