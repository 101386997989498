import { makeStyles } from "@material-ui/core";
import { theme } from "../../../lib/index";

export const useModalAddCertificateStyles = makeStyles({
  modal: {
    marginTop: 30,
  },

  title: {
    paddingTop: 25,
    paddingLeft: 25,
  },

  flex: {
    display: "flex",
  },

  container: {
    display: "flex",
    marginTop: 50,
    marginBottom: 65,
    maxWidth: 1000,

    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 35,
  },

  image: {
    maxWidth: 300,
    maxHeight: 300
  },

  subSubline: {
    ...theme.typography.pSmall,
    marginTop: -10,
    marginBottom: 10,

    color: theme.palette.placeholdergrey,
    textAlign: "center",
  },
});
