import PropTypes from "prop-types";
import { Checkbox, FormControlLabel, makeStyles, Box } from "@material-ui/core";
import { Add, Check } from "@material-ui/icons";

import { theme } from "../../../lib/index";

export default function ToggleButtonFF_newFocus({ input, id, label, deleteFocus }) {
  const useStyles = makeStyles({
    box: {
      position: "relative",

      "&:hover": {
        "& > svg": {
          opacity: 1,

          cursor: "pointer",
        },

        "& .MuiIconButton-label": {
          opacity: 0,
        }
      },
    },

    deleteIcon: {
      position: "absolute",
      top: 27,
      left: 25,

      opacity: 0,

      color: theme.palette.black,

      transform: "scale(1.4) rotate(45deg)",

      transition: `${theme.transitions.create(["opacity"], {
        duration: theme.transitions.duration.short,
      })}`,
    },

    checkbox: {
      margin: "10px 10px 10px 0",
      padding: "15px 24px",
      border: "1px solid transparent",

      borderRadius: 0,
      background: theme.palette.hdc,

      transition: `${theme.transitions.create(["background"], {
        duration: theme.transitions.duration.long,
      })}`,

      "& .MuiFormControlLabel-label": {
        ...theme.typography.pToggleSwitch,
        color: theme.palette.black,
      },

      "&:hover": {
        background: `${theme.palette.lightgrey}`,
      },

      "& .MuiButtonBase-root": {
        padding: "0 10px 0 0",
      },

      "& .MuiIconButton-label": {
        opacity: 1,

        color: theme.palette.black,

        transform: "scale(1.2)",

        transition: `${theme.transitions.create(["opacity"], {
          duration: theme.transitions.duration.short,
        })}`,
      },
    },
  });

  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <FormControlLabel
        className={classes.checkbox}
        label={label}
        control={
          <Checkbox
            name={input.name}
            onClick={() => deleteFocus(id)}
            checked={true}
            checkedIcon={<Check />}
          />
        }
      />

      <Add
        className={classes.deleteIcon}
        onClick={() => deleteFocus(id)}
      />
    </Box>
  );
}

ToggleButtonFF_newFocus.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
};
