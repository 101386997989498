import { useCallback, useState, useEffect } from "react";
import {
  Grid,
  Typography,
  makeStyles,
  Box,
  Snackbar,
} from "@material-ui/core";
import { Form, Field } from "react-final-form";
import {
  Btn,
  Headline,
  UploadButton,
  InputTextfieldFF,
} from "../../../components/index";
import {
  theme,
  constants as c,
  validation as val,
  mediaConstants,
  useMediaQuery,
  usePatchUser,
  useUser,
} from "../../../lib/index";
import AudioPlayer from "./AudioPlayer";

const { NO_AUDIO_PLACEHOLDER_ID } = mediaConstants

const useStyles = makeStyles({
  headline: {
    marginBottom: 10,
  },

  subSubline: {
    ...theme.typography.pSmall,
    marginBottom: 30,

    color: theme.palette.placeholdergrey,
  },

  audioGrid: {
    marginBottom: 80,
  },

  buttonBox: {
    display: "flex",

    gap: 30,
  },

  linkInputContainer: {
    display: "flex",
    marginTop: 9,

    gap: 50,
  },

  button: {
    height: 45,
  },

  videoPreview: {
    width: "100%",
    minHeight: 350
  },

  snackbar: {
    "& .MuiSnackbarContent-root": {
      minWidth: "fit-content",
    }
  },
});

export default function Media() {
  const { mutateAsync: patchUser } = usePatchUser()
  const user = useUser();
  const { content } = user.data;
  const audio = useMediaQuery({ mediaId: content.reader_media_audiosample })
  const video = content.reader_media_videolink;
  const [ytLink, setYtLink] = useState("");
  const [confirmSave, setConfirmSave] = useState(false);
  const userHasAudio = audio.data.id && audio.data.id !== NO_AUDIO_PLACEHOLDER_ID
  const classes = useStyles();

  const handleSaveAudio = useCallback((audioFileId) => {
    const patchedData = { reader_media_audiosample: audioFileId };
    patchUser(patchedData);
    setConfirmSave(true);
  }, [patchUser]);

  const handleDeleteAudio = async () => {
    const patchedData = { reader_media_audiosample: NO_AUDIO_PLACEHOLDER_ID }
    await patchUser(patchedData);
    setConfirmSave(true)
  };

  const handleSaveVideo = async (videolink) => {
    const patchedData = { reader_media_videolink: videolink.video || "" }
    await patchUser(patchedData);
    setConfirmSave(true);
  };

  // change video-URL to embedable url
  useEffect(() => {
    video?.indexOf("watch") > -1
      ? setYtLink(`https://www.youtube.com/embed/${video.split("=")[1]}`)
      : setYtLink(video)
  }, [video]);

  return (
    <>
      <Grid item xs={12}>
        <Headline>Medien</Headline>
      </Grid>

      <Grid item xs={12}>
        <Form
          initialValues={{ audio: audio.data }}
          onSubmit={handleSaveAudio}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Grid item xs={12} lg={6} className={classes.audioGrid}>
                <Typography variant="h3" className={classes.headline}>
                  Hörprobe
                </Typography>

                <Typography>
                  Füge eine Audioaufnahme hinzu, um dich und deine Stimme vorzustellen.
                </Typography>

                <Typography className={classes.subSubline}>
                  .mp3- oder .wav-Format, max. 1MB
                </Typography>

                {userHasAudio && <AudioPlayer src={audio.data.src} />}

                <Box className={classes.buttonBox}>
                  <UploadButton audio handleUploadedFile={handleSaveAudio}>
                    {userHasAudio ? "Audiodatei ändern" : "Audiodatei hinzufügen" }
                  </UploadButton>

                  {userHasAudio && (
                    <Btn flavor={c.BORDER} onClick={() => handleDeleteAudio()}>
                      Audiodatei löschen
                    </Btn>
                  )}
                </Box>
              </Grid>
            </form>
          )}
        />

        <Form
          initialValues={{ video }}
          onSubmit={handleSaveVideo}
          render={({ handleSubmit, dirty }) => (
            <form onSubmit={handleSubmit}>
              <Grid item xs={12} lg={6}>
                <Typography variant="h3" className={classes.headline}>
                  Videovorstellung
                </Typography>

                <Typography>
                  Füge einen Link zu einem Video hinzu, in dem du dich und dein
                  Angebot vorstellst.
                </Typography>

                <Typography className={classes.subSubline}>
                  https://www.youtube.com/...
                </Typography>
              </Grid>

              <Grid item xs={12} className={classes.linkInputContainer}>
                <Field
                  className={classes.linkInputField}
                  component={InputTextfieldFF}
                  fullWidth
                  name="video"
                  placeholder="YouTube-Link"
                  type="text"
                  validate={val.youtube}
                />

                <Btn
                  disabled={!dirty}
                  className={classes.button}
                  flavor={c.BLACK}
                  onClick={(video) => handleSubmit(video)}
                  padding={c.LG}
                >
                  Speichern
                </Btn>
              </Grid>

              {ytLink && (
                <Grid item xs={12}>
                  <iframe
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className={classes.videoPreview}
                    frameBorder="0"
                    src={ytLink}
                    title="Embedded youtube"
                  />
                </Grid>
              )}
            </form>
          )}
        />
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={2000}
        className={classes.snackbar}
        message="Änderungen gespeichert"
        open={confirmSave}
        onClose={() => setConfirmSave(false)}
      />
    </>
  );
}
