import { makeStyles, Grid } from "@material-ui/core";
import { theme } from "../../lib/index";

export default function InnerGrid({ children }) {

  const useStyles = makeStyles({
    outerGrid: {
      margin: "0 auto",
      padding: `${theme.contentPageMarginTop}px 20px 0`,
      width: "100%",

      [theme.breakpoints.up('lg')]: {
        padding: `${theme.contentPageMarginTop}px ${theme.contentPagePaddingSides}px 0`,
      }
    },
  });
  const classes = useStyles();

  return (
    <Grid item className={classes.outerGrid}>
      <Grid container justifyContent="space-between" spacing={2}>
        { children }
      </Grid>
    </Grid>
  );
}