import { makeStyles } from "@material-ui/core";
import { theme } from "../../lib/index";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  headline: {
    ...theme.typography.h1,
    margin: "0 0 62px",
    padding: "0 16px 20px",
    width: "fit-content",

    background:
      `linear-gradient(to bottom, ${theme.palette.white} 0%,${theme.palette.white} 58%,${theme.palette.hdc} 59%,${theme.palette.hdc} 100%)`,

    [theme.breakpoints.down("sm")]: {
      padding: "0 12px 8px",

      fontSize: 35,
    },

    [theme.breakpoints.down("md")]: {
      padding: "0 14px 14px",

      fontSize: 48,
    },
  },
});

export default function Headline(props) {
  const classes = useStyles();

  return (
    <h1 className={`${classes.headline} ${props.className && props.className}`}>
      {props.children ? props.children : null}
    </h1>
  );
}

Headline.propTypes = {
  children: PropTypes.string,
};
