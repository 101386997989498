export function createUpdateFocusPayload(formValues) {
  /**
   * Remap additional Focuses
   */
  const additionalFocusArray = formValues.additionalFocuses.map(e => ({
    reader_profile_focus_hdfocus_additional_uuid: e.id,
    reader_profile_focus_hdfocus_additional_focus: e.focus,
  }));

  /**
   * remap checkboxes
   */
  const rawGenre = {
    reader_profile_focus_genre_private: formValues.privateReadings,
    reader_profile_focus_genre_business: formValues.businessReadings,
  };
  const rawExpertise = {
    reader_profile_focus_expertise_coaching: formValues.coaching,
    reader_profile_focus_expertise_digital: formValues.digital,
    reader_profile_focus_expertise_mediation: formValues.mediation,
  };
  const rawHdFocus = {
    reader_profile_focus_hdfocus_family: formValues.familyAndKids,
    reader_profile_focus_hdfocus_love: formValues.loveAndRelationship,
    reader_profile_focus_hdfocus_business: formValues.business,
    reader_profile_focus_hdfocus_career: formValues.career,
    reader_profile_focus_hdfocus_additional: additionalFocusArray || [],
  };

  return {
    reader_profile_focus_genre: rawGenre,
    reader_profile_focus_expertise: rawExpertise,
    reader_profile_focus_hdfocus: rawHdFocus,
  };
}
