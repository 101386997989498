import PropTypes from "prop-types";
import {
  Table,
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
  makeStyles,
  TableBody,
} from "@material-ui/core";
import { useEffect, useState } from 'react'
import { format, fromUnixTime } from "date-fns";
import { Btn, SortIcon } from "../../components/index";
import { theme, constants as c } from "../../lib/index";

const ASC = 'ascending'
const DESC = 'descending'

function useSortedCustomers(customers) {
  const [sorted, setSorted] = useState(customers)
  const [sortBy, setSortBy] = useState('')
  const [tableSorting, setTableSorting] = useState(ASC);
  const invertSorting = () => setTableSorting(prev => prev === ASC ? DESC : ASC)

  useEffect(() => {
    if (sortBy) {
      setSorted(
        customers.slice().sort((a, b) => {
          const left = a[sortBy]?.toLowerCase()
          const right = b[sortBy]?.toLowerCase()
          return tableSorting === ASC
            ? left > right ? 1 : -1
            : left < right ? 1 : -1
        })
      )
    } else {
      setSorted(customers)
    }
  }, [customers, sortBy, tableSorting])

  return {
    setSortBy,
    sortBy,
    sorted,
    setTableSorting,
    invertSorting,
  }
};

const useStyles = makeStyles({
  table: {
    "& .MuiTableCell-root": {
      border: "none",
    },
  },

  head: {
    border: "none",
    background: theme.palette.lightgrey,

    "& th": {
      ...theme.typography.tableHead,
      color: theme.palette.black,

      "&:nth-child(2)": {
        display: "block",
        [theme.breakpoints.only("md")]: {
          display: "none",
        }
      }
    },
  },

  body: {
    borderColor: "transparent",
    background: theme.palette.white,

    "& .MuiTableCell-body": {
      ...theme.typography.tableText,
      color: theme.palette.black,
      
      "&:nth-child(2)": {
        display: "table-cell",
        [theme.breakpoints.only("md")]: {
          display: "none",
        }
      }
    },

    "& .MuiTableRow-root:nth-child(even)": {
      background: theme.palette.lightgrey,
    },
  },
});

export default function TableCustomerData({ customers, setChosenClientId }) {
  const {
    sorted: rows,
    setSortBy,
    sortBy,
    setTableSorting,
    invertSorting,
  } = useSortedCustomers(customers)

  const classes = useStyles();

  return (
    <TableContainer className={classes.table}>
      <Table>
        <TableHead className={classes.head}>
          <TableRow>
            <TableCell>
              Name
              <SortIcon sorting={() => {
                if (sortBy === 'name') {
                  invertSorting()
                } else {
                  setSortBy('name')
                  setTableSorting(ASC)
                }
              }} />
            </TableCell>
            <TableCell>
              Letztes Reading
              <SortIcon sorting={() => {
                if (sortBy === 'coisarTimestamp') {
                  invertSorting()
                } else {
                  setSortBy('coisarTimestamp')
                  setTableSorting(ASC)
                }
              }} />
            </TableCell>
            <TableCell>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.body}>
          {rows?.map((row) => (
            row.id && (
              <TableRow key={row.id} id={row.id}>
                <TableCell>{row.name}</TableCell>
                <TableCell>
                  {row.coisarTimestamp
                    ? format(fromUnixTime(row.coisarTimestamp), "dd.MM.yyyy")
                    : "-"}
                </TableCell>
                <TableCell>
                  <Btn
                    flavor={c.BORDER}
                    onClick={() => {
                      setChosenClientId(row.id);
                    }}
                  >
                  Details
                  </Btn>
                </TableCell>
              </TableRow>
            )
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

TableCustomerData.propTypes = {
  row: PropTypes.object,
  handleEditCustomerData: PropTypes.func,
};
