import { useCallback, useState } from "react";
import { Form } from "react-final-form";
import { CircularProgress, Grid, Typography, Snackbar } from "@material-ui/core";
import { Stack } from "@mui/material";
import { Headline, EscOrSaveButtons } from "../../../components/index";
import {
  useMediaQuery,
  usePatchUser,
  useUser,
} from "../../../lib/index";
import { ProfileImage } from './ProfileImage'
import { NameField } from './NameField'
import { LocationField } from './LocationField'
import { LanguagesField } from './LanguagesField'
import { IntroductionField } from './IntroductionField'
import { createOnSubmit } from './createOnSubmit'
import { useStyles } from './useStyles.js'
import { useInitialFormState } from './useInitialFormState'

const HDC_LOGO_PLACEHOLDER_ID = 499

export default function Personal() {
  const user = useUser()
  const classes = useStyles();
  const [userImageId, setUserImageId] = useState(user.data?.content?.reader_profile_description_photo);
  const [confirmSave, setConfirmSave] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const screenState = useInitialFormState()
  const userImage = useMediaQuery({ mediaId: userImageId })
  const { mutateAsync: patchUser } = usePatchUser()
  const onSubmit = createOnSubmit({ patchUser, setShowSaveButton, userImageId })

  const handleUserImage = useCallback((data) => {
    setUserImageId(data);
    setShowSaveButton(true);
  }, []);

  const handleDeleteUserImage = () => {
    setUserImageId(HDC_LOGO_PLACEHOLDER_ID);
    setShowSaveButton(true);
  };

  return (
    <>
      <Grid item xs={12}>
        <Headline>Steckbrief</Headline>
      </Grid>

      <Grid item sm={12} md={3} lg={3} className={classes.first}>
        <ProfileImage
          userImage={userImage.data}
          handleUserImage={handleUserImage}
          handleDeleteUserImage={handleDeleteUserImage}
          inactive={screenState.inactive}
        />
      </Grid>

      <Grid item sm={12} md={9} lg={8} className={classes.second}>
        <Typography variant="h3" className={classes.headline}>
          über mich
        </Typography>

        <Form
          initialValues={screenState}
          onSubmit={onSubmit}
          render={({ handleSubmit, dirty, form, submitting }) => (
            <form id="formProfile" onSubmit={handleSubmit}>
              <NameField />

              <Stack direction="row" gap="20px" className="dropdowns">
                <LocationField />
                <LanguagesField />
              </Stack>

              <IntroductionField />
              <EscOrSaveButtons
                disabled={!dirty && !showSaveButton}
                typeSave="submit"
                typeClose="button"
                handleSave={handleSubmit}
                handleClose={form.reset}
                submitStartIcon={
                  submitting
                    ? <CircularProgress color="hdc" size={20} />
                    : undefined
                }
              />
            </form>
          )}
        />
      </Grid>
      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={2000}
        open={confirmSave}
        onClose={() => setConfirmSave(false)}
        message="Änderungen gespeichert"
      />
    </>
  );
}
