import { useMemo } from "react";
import { useUser } from "../../../lib/index";

export function useInitialFormState() {
  const user = useUser()
  const {
    reader_profile_focus_vision: vision,
    reader_profile_focus_visiondetail: visionExplained,
  } = user.data.content;

  // When not using useMemo, the form will shortly toggle to the initial state
  // when submitting the form
  return useMemo(
    () => ({ vision, visionExplained }),
    [vision, visionExplained]
  )
}
