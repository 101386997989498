import { useState } from 'react';
import { Grid, Typography, makeStyles, Snackbar } from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import {
  Headline,
  EscOrSaveButtons,
  InputTextfieldFF,
  CardDownload,
} from '../../../components/index';
import {
  theme,
  usePatchUser,
  useUser,
  validation as val,
} from '../../../lib/index';

const useStyles = makeStyles({
  headline: {
    marginBottom: 10,
  },

  subline: {
    marginBottom: 5,
  },

  subSubline: {
    ...theme.typography.pSmall,
    marginBottom: 30,

    color: theme.palette.placeholdergrey,
  },

  buttonbox: {
    marginBottom: 30,
    paddingTop: 20,

    alignItems: 'end',
  },

  cardDownload: {
    margin: 0,
  },

  snackbar: {
    '& .MuiSnackbarContent-root': {
      minWidth: 'fit-content',
    },
  },
});

export default function Calendar() {
  const [confirmSave, setConfirmSave] = useState(false);
  const { mutateAsync: patchUser } = usePatchUser()
  const user = useUser();
  const classes = useStyles();
  const { content } = user.data;
  const calendar = content.reader_calendar_link
  const initialFormState = { calendar }

  const handleSaveCalendar = async ({ calendar: link }) => {
    const patchedData = { reader_calendar_link: link || "" }
    await patchUser(patchedData)
    setConfirmSave(true)
  };

  return (
    <>
      <Grid item xs={12}>
        <Headline>Kalender</Headline>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Typography variant='h3' className={classes.headline}>
          Calendly
        </Typography>
        <Typography className={classes.subline}>
          Verbinde dein Human Design Club Profil mit deinem Kalender
        </Typography>
        <Typography className={classes.subSubline}>
          https://www.calendly.com/...
        </Typography>
        <Form
          initialValues={initialFormState}
          onSubmit={handleSaveCalendar}
          render={({ handleSubmit, dirty, form }) => (
            <form onSubmit={handleSubmit}>
              <Field
                component={InputTextfieldFF}
                name='calendar'
                fullWidth
                placeholder='Calendly-Link'
                type='text'
                validate={val.mustBeUrl}
              />
              <EscOrSaveButtons
                disabled={!dirty}
                typeSave="submit"
                typeClose="button"
                handleSave={handleSubmit}
                handleClose={form.reset}
              />  
            </form>
          )}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <CardDownload
          buttonName="zu Calendly"
          className={classes.cardDownload}
          downloadLink="https://calendly.com/de"
          noMargin
          text="Calendly ist eine Terminautomatisierungsplattform, mit der man aufwendiges Hin und Her per E-Mail für die Terminfindung vermeiden kann – und noch vieles mehr."
          title="Was ist Calendly?"
        />
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={2000}
        className={classes.snackbar}
        message='Änderungen gespeichert'
        open={confirmSave}
        onClose={() => setConfirmSave(false)}
      />
    </>
  );
}
