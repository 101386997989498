import { useMemo } from "react";
import { mapBackendKeyToStateKey, useUser, typeOfReadingMap } from "../../lib/index";

export function useOffers() {
  const user = useUser();
  const { content } = user.data;

  return useMemo(() => {
    return (content.reader_offers || []).map(offer => {
      const id = offer.reader_offers_uuid;
      const typeOfReading = mapBackendKeyToStateKey( offer.reader_offers_type, typeOfReadingMap);
      const price = offer.reader_offers_price;
      const duration = offer.reader_offers_duration;
      const additionalBenefits = offer.reader_offers_content;

      //console.log("useOffers", { id, typeOfReading, price, duration, additionalBenefits })
      return { id, typeOfReading, price, duration, additionalBenefits };
    });
  }, [content.reader_offers]);
}