import { useCallback, useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { makeStyles, Button } from "@material-ui/core";

import { useEncryptStorage } from "../../lib/index";
import { theme, constants as c } from "../../lib/index";

const { REACT_APP_ROOT_URI } = process.env;

export default function UploadButton({
  audio,
  image,
  handleUploadedFile,
  children,
}) {
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [uploadedFile, setUploadedFile] = useState();
  
  const {
    username: storedUsername,
    password: storedPassword,
  } = useEncryptStorage(store => store.getCredentials())
  const mediaUploadAuth = btoa(storedUsername + ":" + storedPassword);

  let fileType = "";
  if (audio) fileType = c.AUDIO;
  if (image) fileType = c.IMAGE;

  const useStyles = makeStyles({
    input: {
      display: "none",
    },

    button: {
      ...theme.typography.button,
      padding: `15px 24px`,
      width: "fit-content",
      border: `1px solid ${theme.palette.black}`,

      borderRadius: 0,
      background: theme.palette.black,
      boxShadow: "none",

      color: theme.palette.hdc,
      textAlign: "center",

      "&:hover": {
        opacity: 0.75,
        background: theme.palette.black,
        boxShadow: "none",
      },
    },
  });
  const classes = useStyles();

  const saveFile = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const uploadFile = useCallback(() => {
    const formData = new FormData();
    formData.append("formFile", file);
    formData.append("fileName", fileName);
    try {
      axios({
        url: `${REACT_APP_ROOT_URI}media`,
        method: 'POST',
        headers: {
          'Content-Disposition': `form-data; filename=${fileName}`,
          Authorization: `Basic ${mediaUploadAuth}`,
          'Content-Type': `${file.type}`,
        },
        data: file,
      })
        .then(res => {
          setUploadedFile(res.data);
        })
        .catch(err => {
          console.error(err);
        });
    } catch (ex) {
      //console.log(ex)
    }
  }, [fileName, file, mediaUploadAuth]);

  useEffect(() => {
    if (file && (file.media_details?.filesize > 3000000 || file?.size > 3000000)) {
      alert("Datei zu groß. The size of the chosen file exceeds 1MB.")
    } else {
      uploadFile();
    }
  }, [file, uploadFile]);

  useEffect(() => {
    // uploadedFile && console.log("file", uploadedFile);
    uploadedFile && handleUploadedFile(uploadedFile.id);
  }, [uploadedFile, handleUploadedFile]);

  return (
    <>
      <label htmlFor="uploadButton">
        <input
          accept={fileType}
          className={classes.input}
          id="uploadButton"
          name="fileUpload"
          onChange={saveFile}
          type="file"
        />
        <Button variant="contained" component="span" className={classes.button}>
          {children || "Datei hochladen"}
        </Button>
      </label>
    </>
  );
}

UploadButton.propTypes = {
  audio: PropTypes.bool,
  image: PropTypes.bool,
};
