import { v4 as uuid } from "uuid"

export function handleAddFocus(form, addNewFocusValue) {
  if (addNewFocusValue !== "" && isNaN(addNewFocusValue)) {
    const curAdditionalFocuses = form.getState().values.additionalFocuses
    const nextAdditionalFocuses = [
      ...curAdditionalFocuses,
      { id: uuid() , focus: addNewFocusValue }
    ]

    form.batch(() => {
      form.change('additionalFocuses', nextAdditionalFocuses)
      form.change("TextFieldAddNewFocus", undefined)
    })
  }
};
