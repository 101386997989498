import { QueryClient } from '@tanstack/react-query'
import { encryptStorage } from '../lib/index.js'
import axios from 'axios'

const logger = {
  log: console.log,
  warn: console.warn,
  error: () => {},
}

const getDefaultAuth = () => {
  const username = encryptStorage.getItem('username');
  const password = encryptStorage.getItem('password');
  return { username, password };
}

const queryFn = ({ queryKey }) => {
  const [resource, additional = {}] = queryKey
  const { axiosOptions } = additional
  const endpoint = `${process.env.REACT_APP_ROOT_URI}${resource}`
  const defaultAuth = getDefaultAuth();

  return axios.get(endpoint, {
    // Can be overriden by useQuery through axiosOptions
    // An example is the useUser query that also logs in the user
    auth: defaultAuth,
    ...axiosOptions,
  })
}

export const queryClient = new QueryClient({
  logger,
  defaultOptions: {
    queries: {
      queryFn,
      refetchOnWindowFocus: false,
    },
  },
})
