import { useState } from "react";
import { Grid, Typography, Snackbar, makeStyles } from "@material-ui/core";
import { Masonry } from "@mui/lab";
import { Headline } from "../../components/index";
import { theme } from "../../lib/index";
import CardRating from "./CardRating";
import ModalCreateRating from "./ModalCreateRating";
import { useRatings } from './useRatings'

const useStyles = makeStyles({
  gridArea: {
    position: "relative",
  },

  fixed: {
    position: "initial",

    [theme.breakpoints.up("lg")]: {
      position: "sticky",
      top: 100,
    },
  },

  noRatings: {
    ...theme.typography.p,
    marginTop: 30
  },

  snackbar: {
    "& .MuiSnackbarContent-root": {
      minWidth: "fit-content",
    }
  },
});

export default function Ratings() {
  const ratings = useRatings()
  const classes = useStyles();
  const [confirmSave, setConfirmSave] = useState(false);

  return (
    <>
      <Grid item xs={12}>
        <Headline>Bewertungen</Headline>
        <ModalCreateRating />
      </Grid>
      <Masonry columns={{ xs: 1, xl: 2 }} spacing={4}>
        {ratings.length > 0
          ? ratings.map((rating) => (
            <CardRating key={rating.id} rating={rating} />
          ))
          : <Typography className={classes.noRatings}>
                Bisher wurden keine Bewertungen eingetragen.
          </Typography>
        }
      </Masonry>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={2000}
        className={classes.snackbar}
        message="Änderungen gespeichert"
        open={confirmSave}
        onClose={() => setConfirmSave(false)}
      />
    </>
  );
}
