
export const XS = "xs";
export const SM = "sm";
export const MD = "md";
export const LG = "lg";
export const XL = "xl";

export const BLACK = "black";
export const WHITE = "white";
export const YELLOW = "yellow";

export const TRANSPARENT = "trans";
export const BORDER = "border";
export const WHITEBORDER = "whiteborder";

export const AUDIO = ".mp3, .wav";
export const IMAGE = ".jpg, .jpeg, .png, .webp";