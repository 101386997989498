import format from "date-fns/format";
import {
  TextField,
  makeStyles,
  Container,
  InputLabel,
} from "@material-ui/core";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";

import { theme } from "../../lib/index";

export default function DatepickerFF({ input, meta, ...rest }) {
  const useStyles = makeStyles({
    container: {
      marginBottom: `${rest.noMargin ? 0 : "35px"}`,
      padding: 0,
    },

    label: {
      ...theme.typography.label,
      display: `${!rest.label && "none"}`,
      paddingBottom: "9px",

      color: theme.palette.darkgrey,
    },

    datepicker: {
      width: `${rest.fullWidth ? "100%" : "inherit"}`,
      border: "1px solid transparent",

      background: theme.palette.backgroundgrey,

      "& .MuiFilledInput-underline:before,& .MuiFilledInput-underline:after": {
        border: `1px solid transparent`,
      },

      "& .MuiInput-underline:before, & .MuiInput-underline:after": {
        borderBottom: "none",
      },

      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        border: "none",
      },

      "& .MuiInputBase-input": {
        padding: "12px 15px",
        border: `${
          meta.touched && meta.error
            ? "1px solid " + theme.palette.alert.main
            : "1px solid transparent"
        }`,
      },

      "& .MuiInputBase-input:focus": {
        borderColor: theme.palette.hdc,
      },

      "& .MuiIconButton-root:hover": {
        background: "none",

        color: theme.palette.black,

        transform: "scale(1.2)",

        transition: `${theme.transitions.create(["transform", "color"], {
          duration: theme.transitions.duration.long,
        })}`,
      },

      "&:hover": {
        borderColor: `${theme.palette.placeholdergrey}50`,
      },

      "& .MuiButtonBase-root": {
        margin: 0,
      },
    },

    errorMessage: {
      color: theme.palette.alert.main,
    },
  });

  const classes = useStyles();

  return (
    <Container
      className={`${classes.container} ${rest.className && rest.className}`}
    >
      <InputLabel className={classes.label}>
        {rest.label}
        {rest.required && " *"}
      </InputLabel>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          inputFormat="dd.MM.yyyy"
          mask="__.__.____"
          required={rest.required}
          value={input.value}
          onChange={(newDate) => {
            const dateString = `${newDate}`;
            if (dateString !== "null" && dateString !== "Invalid Date") {
              input.onChange(format(newDate, "dd.MM.yyyy"));
            } else {
              input.onChange("");
            }
          }}
          renderInput={(params)=> {
            return <TextField
              className={classes.datepicker}
              required={rest.required}
              {...params}
              inputProps={{ ...params.inputProps, value: input.value }}
              helperText={null}
            />
          }}
        />
      </LocalizationProvider>
      {meta.touched && meta.error && (
        <span className={classes.errorMessage}>{meta.error}</span>
      )}
    </Container>
  );
}
