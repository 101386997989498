import { useMemo, useState } from "react";
import { makeStyles, Grid, Snackbar } from "@material-ui/core";
import { Headline } from "../../../components/index";
import SocialMediaDisplay from "./SocialMediaDisplay";
import ModalSocialMedia from "./ModalSocialMedia";
import { useUser } from "../../../lib/index";
import { computeActiveSocialMedia } from './computeActiveSocialMedia'
import { useHandleDeleteSocialMedia } from './useHandleDeleteSocialMedia'

const useStyles = makeStyles({});

function useUserSocialMedia() {
  const user = useUser()

  return useMemo(
    () => computeActiveSocialMedia(
      user.data.content.reader_profile_description_socials
    ),
    [user.data.content.reader_profile_description_socials]
  )
}

export default function SocialMedia() {
  const classes = useStyles();
  const [confirmSave, setConfirmSave] = useState(false);
  const socialMedia = useUserSocialMedia()
  const handleDeleteSocialMedia = useHandleDeleteSocialMedia()

  return (
    <>
      <Grid item xs={12}>
        <Headline>Social Media</Headline>
        <ModalSocialMedia onAdded={() => setConfirmSave(true)} />
      </Grid>
      <Grid item xs={12} lg={4}>
        {socialMedia.length > 0 && (
          <SocialMediaDisplay
            socialMedia={socialMedia}
            handleDeleteSocialMediaElement={plattform => {
              handleDeleteSocialMedia(plattform).then(confirmSave)
            }}
          />
        )}
      </Grid>
      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={2000}
        open={confirmSave}
        onClose={() => setConfirmSave(false)}
        message="Änderungen gespeichert"
      />
    </>
  )
}
