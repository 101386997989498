import { useState } from "react";
import PropTypes from "prop-types";
import { Dialog, Box, makeStyles, Typography } from "@material-ui/core";
import { Close, Delete } from "@material-ui/icons";

import { Btn, IconButtonHdc } from "../../components/index";
import { constants as c } from "../../lib/index"

export default function DeleteButtonWithConfirmation({ itemToDelete, deleteFunction, deleteIcon, className }) {
  const [open, setOpen] = useState(false);

  const useStyles = makeStyles({
    modal: {
      heigth: "min-content",

      "& .MuiPaper-elevation24": {
        overflow: "hidden",
        padding: 50,
        maxWidth: "50vw",

        boxShadow: "none",
      },
    },

    closeButton: {
      position: "absolute",
      top: 4,
      right: 4,
    },

    buttonBox: {
      display: "flex",
      marginTop: 30,

      justifyContent: "center",
      alignItems: "center",
      gap: 30,
    }
  });

  const classes = useStyles();

  const handleConfirm = () => {
    deleteFunction();
    setOpen(false);
  };

  return (
    <>
      {deleteIcon ?
        <IconButtonHdc
          onClick={() => setOpen(true)}
        >
          <Delete />
        </IconButtonHdc>
        :
        <Btn
          className={className}
          onClick={() => setOpen(true)}
          type={c.TRANSPARENT}
        >
      Bewertung entfernen
        </Btn>
      }
      <Dialog
        maxWidth="md"
        open={open}
        onClose={() => setOpen(false)}
        className={classes.modal}
      >
        <IconButtonHdc onClick={() => setOpen(false)} className={classes.closeButton}>
          <Close />
        </IconButtonHdc>
        <Typography variant="h5" className={classes.title}>{`Möchtest du den Eintrag "${itemToDelete}" wirklich löschen?`}</Typography>
        <Box className={classes.buttonBox}>
          <Btn onClick={() => setOpen(false)} flavor={c.BORDER}>Abbrechen</Btn>
          <Btn onClick={handleConfirm} flavor={c.BLACK}>Löschen</Btn>
        </Box>
      </Dialog>
    </>
  );
}

DeleteButtonWithConfirmation.propTypes = {
  toDelete: PropTypes.string,
  deleteFunction: PropTypes.func,
  deleteIcon: PropTypes.bool,
  isForm: PropTypes.bool
};
