import { useMemo } from "react";
import {
  languageMap,
  mapBackendKeyToStateKey,
  useUser,
} from "../../../lib/index";

const initialScreenState = {
  name: "",
  introduction: "",
  pictureId: 60,
  location: "",
  languages: [],
  socials: [],
  inactive: true,
};

export function useInitialFormState() {
  const user = useUser()

  return useMemo(() => {
    if (!user.data?.content) {
      return initialScreenState
    }

    const {
      reader_profile_description_fullname: name,
      reader_profile_description_introduction: introduction,
      reader_profile_description_languages: rawLanguages,
      reader_profile_description_location: location,
      reader_profile_description_photo: pictureId,
      reader_calendar_inactive: inactive,
    } = user.data.content;
    
    /**
     * Map languages, e.g.
     * reader_profile_description_languages_german === true => "Deutsch"
     */
    const languages = Object
      .entries(rawLanguages)
      .filter(([, value]) => value)
      .map(([key]) => mapBackendKeyToStateKey(key, languageMap))

    return {
      name,
      introduction,
      location,
      languages,
      pictureId,
      inactive
    };
  }, [user.data?.content])
}
