import { createTheme } from "@material-ui/core";

export let theme = createTheme({
  // MENU-SIZES
  menuWidthDesktop: 210,

  submenuWidthDesktop: 220,
  submenuPaddingTop: 126,

  // CONTENT PAGE
  contentPageMaxWidth: 1390,
  contentPageMarginTop: 70,
  contentPagePaddingSides: 50,

  // BREAKPOINTS
  breakpoints: {
    values: {
      // customer uses an old 13" MacBook (1280x800) -> now available as "md"
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1281,
      xl: 1681
    },
  },

  // COLORS
  palette: {
    black: "#000000",
    white: "#FFFFFF",
    hdc: "#FFE600",
    darkgrey: "#202020",
    placeholdergrey: "#9E9E9E",
    midgrey: "#C8C8C8",
    lightgrey: "#EFEFEF",
    backgroundgrey: "#FAFAFA",
    primary: {
      main: "#000000",
      contrastText: "#FFE600",
    },
    secondary: {
      main: "#FFE600",
      dark: "#FFE60075",
      contrastText: "#000000",
    },
    alert: {
      main: "#FF214D",
    },
  },

  // TYPOGRAPHY
  typography: {
    fontFamily: "Open Sans",

    h1: {
      fontFamily: "Dosis",
      fontSize: 64,
      lineHeight: "115%",
      letterSpacing: "0.02em",
      fontWeight: 400,
    },
    h2: {
      fontFamily: "Dosis",
      fontSize: 54,
      lineHeight: "130%",
      letterSpacing: 1,
      fontWeight: 400,
    },
    h3: {
      fontFamily: "Open Sans",
      fontSize: 28,
      lineHeight: "140%",
      letterSpacing: 1,
      fontWeight: 700,
      textTransform: "uppercase",
    },
    h4: {
      fontFamily: "Open Sans",
      fontSize: 28,
      lineHeight: "140%",
      letterSpacing: 1,
      fontWeight: 400,
      textTransform: "uppercase",
    },
    h5: {
      fontFamily: "Open Sans",
      fontSize: 22,
      lineHeight: "140%",
      letterSpacing: 1,
      fontWeight: 600,
    },
    h6: {
      fontFamily: "Open Sans",
      fontSize: 20,
      lineHeight: "140%",
      letterSpacing: 1,
      fontWeight: 600,
    },
    button: {
      fontFamily: "Open Sans",
      fontSize: 14,
      lineHeight: "140%",
      letterSpacing: 1,
      fontWeight: 600,
      textTransform: "uppercase",
    },
    menulink: {
      fontFamily: "Open Sans",
      fontSize: 18,
      lineHeight: "30px",
      letterSpacing: 1,
      fontWeight: 400,
    },
    p: {
      fontFamily: "Open Sans",
      fontSize: 16,
      lineHeight: "26px",
      letterSpacing: 0.5,
      fontWeight: 400,
    },
    pBig: {
      fontFamily: "Open Sans",
      fontSize: 20,
      lineHeight: "30px",
      letterSpacing: 1,
      fontWeight: 400,
    },
    pSmall: {
      fontFamily: "Open Sans",
      fontSize: 13,
      lineHeight: "19.5px",
      letterSpacing: 1,
      fontWeight: 400,
    },
    pBold: {
      fontFamily: "Open Sans",
      fontSize: 16,
      lineHeight: "24px",
      letterSpacing: 0.5,
      fontWeight: 700,
    },
    pToggleSwitch: {
      fontFamily: "Open Sans",
      fontSize: 16,
      lineHeight: "24px",
      fontWeight: 600,
      textTransform: "uppercase",
    },
    label: {
      fontFamily: "Open Sans",
      fontSize: 13,
      lineHeight: "19.5px",
      letterSpacing: 0.5,
      fontWeight: 600,
    },
    tableHead: {
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: 1.5,
      whiteSpace: "pre"
    },
    tableText: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: 1.5,
    },
  },

  // TRANSITIONS
  transitions: {
    duration: {
      long: 500,
      longest: 1000,
    },
  },

  // MISCELLANEOUS
  shape: {
    borderRadius: 0,
  },
});
