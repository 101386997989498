import { Field } from "react-final-form";
import { InputTextfieldFF } from "../../../components/index";
import { validation as val } from "../../../lib/index";

export function LocationField() {
  return (
    <Field
      component={InputTextfieldFF}
      fullWidth
      label="Mein Standort"
      name="location"
      placeholder="Wo lebst du?"
      required
      type="text"
      validate={val.composeValidators(val.mustBeText, val.required)}
    />
  )
}
