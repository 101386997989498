import PropTypes from "prop-types";
import {
  makeStyles,
  Container,
  Typography,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { Stack } from "@mui/material";

import { IconButtonHdc } from "../../../components/index";
import { theme } from "../../../lib/index";

export default function SocialMediaDisplay({
  socialMedia,
  handleDeleteSocialMediaElement,
}) {

  const useStyles = makeStyles({
    container: {
      marginBottom: 35,
      padding: 0,

      "& a": {
        textDecoration: "none",
        color: theme.palette.black,
      }
    },
  });

  const classes = useStyles();

  return (
    <>
      {socialMedia.map((entry, index) => (
        <Container className={classes.container} key={index} id={"socialMedia" + index}>
          <Stack direction="row" gap="20px" alignItems="center">
            <Typography variant="h4">{entry.plattform}</Typography>
            <IconButtonHdc onClick={() => handleDeleteSocialMediaElement(entry.plattform)}>
              <Delete />
            </IconButtonHdc>
          </Stack>
          <a href={entry.link} target="_blank" rel="noreferrer">{entry.link}</a>
        </Container>
      ))}
    </>
  );
}

SocialMediaDisplay.propTypes = {
  socialMedia: PropTypes.array,
  handleDeleteSocialMediaElement: PropTypes.func,
};
