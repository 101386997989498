import { Grid } from "@material-ui/core";

import { Headline, CardDownload } from "../../components/index";
import { constants as c } from "../../lib/index";

export default function Downloads() {
  return (
    <>
      <Grid item xs={12}>
        <Headline>Downloads</Headline>
      </Grid>
      <Grid item xs={12} md={5} lg={4} >
        <CardDownload
          background={c.BLACK}
          title="Datenschutz- verordnung"
          text="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montesL"
          downloadLink ="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
        />
      </Grid>
      <Grid item xs={12} md={7} lg={8} >
        <CardDownload
          background={c.WHITE}
          title="Impressumsvorlage"
          text="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montesLorem ipsum dolor sit amet,Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes.et dolor. Aenean massa. Cum sociis et dolor. Aenean massa. Cum sociis et dolor. Aenean massa. Cum sociis  ontesLorem ipsum"
          downloadLink ="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
        />
      </Grid>
      <Grid item xs={12} md={5} lg={4}>
        <CardDownload
          background={c.YELLOW}
          title="PDF-Anleitung"
          text="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montesL"
          downloadLink ="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
        />
      </Grid>
      <Grid item xs={12} md={7} lg={8}>
        <CardDownload
          background={c.BLACK}
          title="AGB-VOrlage"
          text="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. "
          downloadLink ="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
        />
      </Grid>
    </>
  );
}
