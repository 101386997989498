import { useCallback, useState, useRef, useEffect } from "react";
import { Card, Slider, Typography, makeStyles } from "@material-ui/core";
import { PlayCircleFilled, PauseCircleFilled } from "@material-ui/icons";

import { theme } from "../../../lib/index";
import { IconButtonHdc } from "../../../components/index";

const AudioPlayer = (props) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [position, setPosition] = useState(0);

  const audioPlayer = useRef();
  const progressBar = useRef();
  const animationRef = useRef();

  useEffect(() => {
    setDuration(audioPlayer.current.duration);
  }, [audioPlayer?.current?.loadedmetadata, audioPlayer?.current?.readyState]);

  const calculateTime = (secs) => {
    const minutes = Math.floor(secs / 60);
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${returnedMinutes}:${returnedSeconds}`;
  };

  const whilePlaying = useCallback(() => {
    if (audioPlayer) {
      progressBar.current.value = audioPlayer.current.currentTime;
      setPosition(progressBar.current.value);
      animationRef.current = requestAnimationFrame(whilePlaying);
    }
  }, []);

  const togglePlayPause = useCallback(() => {
    const prevValue = isPlaying;
    setIsPlaying(!prevValue);
    if (!prevValue) {
      audioPlayer.current.play();
      animationRef.current = requestAnimationFrame(whilePlaying);
    } else {
      audioPlayer.current.pause();
      cancelAnimationFrame(animationRef.current);
    }
  }, [whilePlaying, isPlaying]);

  // go back to start when reached the end
  useEffect(() => {
    if(position !== 0 && position === duration) {
      togglePlayPause();
      progressBar.current.value = 0
    }
  }, [position, duration, togglePlayPause]);

  const changeRange = (value) => {
    if (!isNaN(value)) {
      audioPlayer.current.currentTime = value;
      setPosition(audioPlayer.current.currentTime);
    }
  };

  const useStyles = makeStyles({
    container: {
      position: "relative",

      display: "flex",
      margin: "25px 0 35px 0",
      padding: "20px 25px 20px 20px",
      width: "fit-content",

      alignItems: "center",

      borderRadius: 17,
      boxShadow: "4px 4px 16px rgba(0, 0, 0, 0.15)",

      "& .MuiIconButton-root": {
        marginRight: 20,
        padding: 0,

        background: "transparent",

        transition: `${theme.transitions.create(["background"], {
          duration: theme.transitions.duration.standard,
        })}`,
      },
    },

    button: {
      width: 70,
      height: 70,

      background: `radial-gradient(ellipse at center,
        ${theme.palette.hdc} 0%,
        ${theme.palette.hdc} 50%,
        ${theme.palette.black} 51%,
        ${theme.palette.black} 100%)`,
      borderRadius: "50%",

      color: theme.palette.black,

      transition: `${theme.transitions.create(["color"], { duration: theme.transitions.duration.long })}`,

      "&:hover": {
        background: theme.palette.black,

        color: theme.palette.hdc,
      },
    },

    slider: {
      width: 350,

      "& .MuiSlider-thumb": {
        marginTop: "-7px",
        width: 16,
        height: 16,

        background: theme.palette.hdc,
      },
    },

    counter: {
      position: "absolute",
      right: 25,
      bottom: 28,

      color: theme.palette.placeholdergrey,
      fontSize: 12,
      lineHeight: "17px",
    },
  });

  const classes = useStyles();

  return (
    <Card className={classes.container}>
      <audio ref={audioPlayer} src={props.src} type="audio/mpeg" />
      <IconButtonHdc onClick={togglePlayPause}>
        {!isPlaying ? (
          <PlayCircleFilled className={classes.button} />
        ) : (
          <PauseCircleFilled className={classes.button} />
        )}
      </IconButtonHdc>
      <Slider
        ref={progressBar}
        className={classes.slider}
        value={position}
        min={0}
        step={1}
        max={duration}
        onChange={(_, value) => changeRange(value)}
      />
      <Typography className={classes.counter}>
        {position ? calculateTime(position) : "0:00"} /{" "}
        {duration ? calculateTime(duration) : "0:00"}
      </Typography>
    </Card>
  );
};

export default AudioPlayer;
