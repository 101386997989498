import { mapStateKeyToBackendKey, typeOfReadingMap } from "../../lib";

export function formatOffersForApi(offers) {
  return offers.map(offer => {
    return {
      reader_offers_uuid: offer.id,
      reader_offers_type: mapStateKeyToBackendKey(offer.typeOfReading, typeOfReadingMap),
      reader_offers_price: offer.price,
      reader_offers_duration: offer.duration,
      reader_offers_content: offer.additionalBenefits || "",
    };
  })
}