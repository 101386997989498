import { encryptStorage } from "./encryption/index";
import axios from "axios";

export const loadMedia = (mediaId, setState) => {
  if (mediaId) {
    try {
      const username = encryptStorage.getItem('username');
      const password = encryptStorage.getItem('password');
      const auth = { username, password };
      axios.get('https://backend.hdc.i-pioneers.de/wp-json/wp/v2/media/' + mediaId, {
        auth,
      }).then(res => {
        setState({
          id: res.data.id,
          src: res.data.source_url,
          alt: res.data.alt_text,
        });
      });
    } catch (error) {
      console.error("get media", error.message);
    }
  }
};