import { useState } from 'react';
import { Form, Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { makeStyles, Typography, Box } from '@material-ui/core';
import { Stack } from '@mui/material';
import { Add } from '@material-ui/icons';
import { v4 as uuid } from 'uuid';
import {
  DatepickerFF,
  EscOrSaveButtons,
  InputTextfieldFF,
  ModalHdc,
} from '../../components/index';
import {
  constants as c,
  dateForApp,
  usePatchUser,
  validation as val,
} from '../../lib/index';
import AutocompletePlaceFF from './AutocompletePlaceFF';
import { useCustomers } from './useCustomers.js'

function getMode(customer) {
  if (customer?.coisarStatus === "none") {
    return 'edit';
  }

  if (
    customer?.coisarStatus === "received" ||
    customer?.coisarStatus === "ordered"
  ) {
    return "notesOnly";
  }

  return "new";
}

const useStyles = makeStyles({
  title: {
    margin: '50px auto 0',
  },

  stack: {
    margin: '50px auto 0',
    width: 800,
  },

  box: {
    display: 'flex',
    gap: 50,
  },

  customerData: {
    margin: "50px auto 0",
    textAlign: "center"
  }
});

export default function ModalCustomer({
  customerId,
  customers,
  handleClickOpen,
  handleClose,
  open,
}) {
  const customer = customers.find(({ id }) => id === customerId);
  const customersRaw = useCustomers({ suppressTransform: true });
  const { mutateAsync: patchUser } = usePatchUser();
  const mode = getMode(customer);
  const [timezone, setTimezone] = useState("");
  const classes = useStyles();

  customersRaw.forEach((entry) => {
    if (!entry.reader_clients_image) {
      entry.reader_clients_image = 2581
    }})

  function addNewCustomer(newCustomer) {
    return patchUser({
      reader_clients: [...customers, {
        reader_clients_uuid: newCustomer.id,
        reader_clients_name: newCustomer.name,
        reader_clients_birthdate: newCustomer.birthdate,
        reader_clients_birthplace: newCustomer.birthplace,
        reader_clients_birthtimezone: newCustomer.birthtimezone,
        reader_clients_birthtime: newCustomer.birthtime,
        reader_clients_text: newCustomer.notes,
        reader_clients_image: newCustomer.image || 2581,
        reader_clients_shopify_id: "",
      }],
    })
  }

  function editCustomer(editedCustomer) {
    const updatedCustomers = customersRaw.map(customer => {
      return customer.reader_clients_uuid !== customerId
        ? customer
        : {
          reader_clients_name: editedCustomer.name,
          reader_clients_birthdate: editedCustomer.birthdate,
          reader_clients_birthtimezone: editedCustomer.birthtimezone,
          reader_clients_birthtime: editedCustomer.birthtime,
          reader_clients_birthplace: editedCustomer.birthplace,
          reader_clients_text: editedCustomer.notes,
        }
    })
    return patchUser({ reader_clients: updatedCustomers })
  }

  function editNotes(notes) {
    const updatedCustomers = customersRaw.map(customer => {
      return customer.reader_clients_uuid !== customerId
        ? customer
        : { ...customer, reader_clients_text: notes }
    })
    return patchUser({ reader_clients: updatedCustomers })
  }

  return (
    <ModalHdc
      buttonName='Kunde hinzufügen'
      buttonType={c.BLACK}
      className={classes.modal}
      fullWidth
      handleClickOpen={handleClickOpen}
      handleClose={handleClose}
      open={open}
      startIcon={<Add />}
    >
      <Typography variant='h3' className={classes.title}>
        {mode === "notesOnly" 
          ? "Notizen bearbeiten"
          : mode === "edit" 
            ? "Kundendaten ändern"
            : "Neuer Kunde"
        } 
      </Typography>
      { mode === "notesOnly" &&
        <Box className={classes.customerData}>
          <Typography variant='h6'>{customer.name}</Typography>
          <Typography>
            Geboren am {dateForApp(customer.birthdate)} um{' '}
            {customer.birthtime}
          </Typography>
          <Typography>in {customer.birthplace}</Typography>
        </Box>
      } 
      <Form
        initialValues={{ ...customer }}
        onSubmit={async (values) => {
          const newCustomerData = {
            ...values,
            birthtimezone: timezone,
            notes: values.notes || "",
            image: values.image || 2581,
            id: uuid(),
            shopyfyId: "",
          };

          if (mode === "notesOnly") {
            await editNotes(newCustomerData.notes);
          } else if (mode === "edit") {
            await editCustomer(newCustomerData);
          } else {
            await addNewCustomer(newCustomerData);
          }

          handleClose();
        }}
      >
        {({ handleSubmit, dirty }) => (
          <form onSubmit={handleSubmit}>
            <Stack className={classes.stack}>
              {mode !== "notesOnly" &&
              <>
                <Box className={classes.box}>
                  <Field
                    component={InputTextfieldFF}
                    fullWidth
                    label='Name'
                    name='name'
                    placeholder='Vorname Nachname'
                    required
                    type='text'
                    validate={val.composeValidators(val.required, val.mustBeText)}
                  />
                  <Field
                    component={DatepickerFF}
                    fullWidth
                    label='Geburtsdatum'
                    name='birthdate'
                    placeholder='TT.MM.JJJJ'
                    required
                    validate={val.composeValidators(val.required, val.mustBeDate)}
                  />
                </Box>
                <Box className={classes.box}>
                  <Field
                    component={AutocompletePlaceFF}
                    fullWidth
                    label='Geburtsort (momentan nur englische Ortsnamen)'
                    name='birthplace'
                    placeholder='Ort'
                    required
                    setTimezone={setTimezone}
                    type='text'
                    validate={val.composeValidators(val.required, val.mustBeText)}
                  />
                  <Field
                    component={InputTextfieldFF}
                    fullWidth
                    label='Geburtszeit (24h-Format)'
                    name='birthtime'
                    placeholder='hh:mm'
                    required
                    validate={val.composeValidators(val.required, val.mustBeTime)}
                  />
                </Box>
              </>
              }
              <Box className={classes.box}>
                <Field
                  component={InputTextfieldFF}
                  fullWidth
                  label='Eigene Notizen'
                  multiline
                  name='notes'
                  placeholder='Deine Notizen'
                  rows={5}
                  type='text'
                />
              </Box>
              <EscOrSaveButtons
                disabled={!dirty}
                modal
                handleClose={handleClose}
                handleSave={() => handleSubmit()}
              />
            </Stack>
          </form>
        )}
      </Form>
    </ModalHdc>
  );
}

ModalCustomer.propTypes = {
  handleFormData: PropTypes.func,
  incomingCustomerData: PropTypes.object,
};
