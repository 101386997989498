import { useMemo } from "react";
import { useUser } from "../../../lib/index";

export function useInitialFormState() {
  const user = useUser()

  // When not using useMemo, the form will shortly toggle to the initial state
  // when submitting the form
  return useMemo(() => {
    const {
      reader_profile_focus_genre: rawGenre,
      reader_profile_focus_expertise: rawExpertise,
      reader_profile_focus_hdfocus: rawHdFocus,
    } = user.data.content;

    const rawAdditionalFocuses = rawHdFocus.reader_profile_focus_hdfocus_additional;
    const additionalFocuses = rawAdditionalFocuses?.map(focus => {
      const ID = focus.reader_profile_focus_hdfocus_additional_uuid;
      const text = focus.reader_profile_focus_hdfocus_additional_focus;
      return { id: ID, focus: text };
    }) || []

    return {
      additionalFocuses,
      privateReadings: rawGenre.reader_profile_focus_genre_private,
      businessReadings: rawGenre.reader_profile_focus_genre_business,
      familyAndKids: rawHdFocus.reader_profile_focus_hdfocus_family,
      loveAndRelationship: rawHdFocus.reader_profile_focus_hdfocus_love,
      business: rawHdFocus.reader_profile_focus_hdfocus_business,
      career: rawHdFocus.reader_profile_focus_hdfocus_career,
      coaching: rawExpertise.reader_profile_focus_expertise_coaching,
      digital: rawExpertise.reader_profile_focus_expertise_digital,
      mediation: rawExpertise.reader_profile_focus_expertise_mediation,
    }
  }, [user.data.content])
}
