import { makeStyles, IconButton } from "@material-ui/core";
import { theme } from "../../lib/index";

const useStyles = makeStyles({
  hover: {
    transition: `${theme.transitions.create(["transform"], {
      duration: theme.transitions.duration.standard,
    })}`,
    "&:hover": {
      background: "transparent",

      color: theme.palette.black,

      transform: "scale(1.2)",
    },
  },
});

export default function IconButtonHdc(props) {
  const classes = useStyles();

  return (
    <IconButton
      className={`${classes.hover} ${props.className && props.className}`}
      onClick={props.onClick}
      tabIndex="-1"
    >
      {props.children}
    </IconButton>
  );
}
