import PropTypes from "prop-types";
import { Button, makeStyles } from "@material-ui/core";

import { theme, constants as c } from "../../lib/index";
export default function Btn(props) {
  let size = [15, 24];
  if (props.padding === c.SM) size = [7, 15];
  if (props.padding === c.MD) size = [12, 19];
  if (props.padding === c.LG) size = [15, 24];

  const useStyles = makeStyles({
    button: {
      padding: `${size[0]}px ${size[1]}px`,
      width: "fit-content",

      borderRadius: 0,

      textTransform: props.noUppercase ? "initial" : "uppercase",

      transition: "background 500ms ease, color 500ms ease"
    },

    yellowOnBlack: {
      background: props.disabled ? theme.palette.lightgrey : theme.palette.black,

      color: theme.palette.hdc,

      "&:hover": {
        opacity: 0.75,
        background: theme.palette.black,
      },
    },

    blackOnTransparent: {
      background: "transparent",

      color: theme.palette.black,

      "&:hover": {
        opacity: 0.75,
        background: theme.palette.lightGrey,
      },
    },

    blackOnWhite: {
      border: `1px solid ${theme.palette.black}`,

      background: "transparent",

      color: theme.palette.black,

      "&:hover": {
        opacity: 0.75,
        background: theme.palette.lightgrey,
      },
    },

    whiteBorder: {
      border: `1px solid ${theme.palette.white}`,

      background: "transparent",

      color: theme.palette.white,

      "&:hover": {
        opacity: 0.75,
        background: theme.palette.darkgrey,
      },
    },
  });

  const classes = useStyles();

  let flavorClass = "";
  if (props.flavor === c.BLACK) flavorClass = classes.yellowOnBlack;
  if (props.flavor === c.TRANSPARENT) flavorClass = classes.blackOnTransparent;
  if (props.flavor === c.BORDER) flavorClass = classes.blackOnWhite;
  if (props.flavor === c.WHITEBORDER) flavorClass = classes.whiteBorder;

  return (
    <Button
      disabled={props.disabled}
      type={props.type}
      href={props.href}
      target={props.href && "_blank"}
      rel={props.href && "noopener noreferrer"}
      onClick={props.onClick}
      padding={props.padding}
      startIcon={props.startIcon}
      className={`${classes.button} ${props.className && props.className} ${flavorClass}`}
    >
      {props.children ? props.children : null}
    </Button>
  );
}

Btn.propflavors = {
  props: {
    children: PropTypes.string,
    flavor: PropTypes.oneOf([c.BLACK, c.TRANSPARENT, c.BORDER, c.WHITEBORDER]),
    padding: PropTypes.oneOf([c.SM, c.MD, c.LG]),
    startIcon: PropTypes.node,
    onClick: PropTypes.func,
    href: PropTypes.string,
    disabled: PropTypes.bool,
  },
};
