import { Form, Field } from "react-final-form";
import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";
import { makeStyles, Typography, Box } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Stack } from "@mui/material";
import { theme, constants as c, validation as val } from "../../lib/index";
import { ModalHdc, DropDownFF, EscOrSaveButtons, InputTextfieldFF } from "../../components/index";
import { useHandleNewOffer } from "./useHandleNewOffer";

const useStyles = makeStyles({
  title: {
    margin: "50px auto 0",
  },

  inner: {
    margin: "50px auto 0",
    width: "80%",
  },

  hint: {
    ...theme.typography.pSmall,
    marginTop: -25,
    marginBottom: 35,
  },
});

export default function ModalAddOffer({
  dataToEdit,
  openModal,
  handleClose,
  open,
}) {

  const handleNewOffer = useHandleNewOffer()
  const classes = useStyles();

  return (
    <ModalHdc
      buttonName="Angebot hinzufügen"
      buttonType={c.BLACK}
      className={classes.modal}
      fullWidth
      handleClickOpen={openModal}
      handleClose={handleClose}
      open={open}
      startIcon={<Add />}
    >
      <Typography variant="h3" className={classes.title}>
        Angebot hinzufügen
      </Typography>
      <Form
        initialValues={{ ...dataToEdit }}
        onSubmit={(newOfferData) => {
          newOfferData = {
            id: uuid(),
            business:
              newOfferData.typeOfReading === "Business Reading" ||
              newOfferData.typeOfReading === "Karriere Reading"
                ? "ja"
                : "nein",
            ...newOfferData,
          };
          handleNewOffer(newOfferData);
          handleClose();
        }}
      >
        {({ handleSubmit, dirty }) => (
          <form onSubmit={handleSubmit}>
            <Box className={classes.inner}>
              <Field
                component={DropDownFF}
                fullWidth
                items={[
                  { choice: "Standard Reading" },
                  { choice: "Business Reading" },
                  { choice: "Familien Reading" },
                  { choice: "Partner Reading" },
                  { choice: "Kinder Reading" },
                  { choice: "Transit Report" },
                ]}
                label="Art"
                name="typeOfReading"
                preSelected={dataToEdit?.typeOfReading}
                placeholder="Welche Art von Reading willst du anbieten?"
                required
                validate={val.required}
              />
              <Stack direction="row" gap="20px">
                <Field
                  component={InputTextfieldFF}
                  fullWidth
                  label="Preis in €"
                  name="price"
                  placeholder="zB. 200"
                  required
                  type="number"
                  validate={val.composeValidators(val.mustBeNumber, val.required)}
                />
                <Field
                  component={InputTextfieldFF}
                  fullWidth
                  label="Dauer in Minuten"
                  name="duration"
                  placeholder="zB. 60"
                  required
                  type="number"
                  validate={val.composeValidators(val.mustBeNumber, val.required)}
                />
              </Stack>
              <Typography className={classes.hint}>
                Coisar-Gebühr wird davon abgezogen (49€)
              </Typography>
              <Field
                characterLimit={500}
                component={InputTextfieldFF}
                counter
                fullWidth
                label="zusätzliche Leistungen"
                multiline
                name="additionalBenefits"
                placeholder="Gib kurz einen Überblick, über die Inhalte (max. 500 Zeichen)"
                rows={5}
                type="text"
                validate={val.composeValidators(
                  val.mustBeText,
                  val.limitCharacters500
                )}
              />
              <EscOrSaveButtons
                disabled={!dirty}
                handleClose={handleClose}
                handleSave={() => handleSubmit()}
                modal
              />
            </Box>
          </form>
        )}
      </Form>
    </ModalHdc>
  );
}

ModalAddOffer.propTypes = {
  dataToEdit: PropTypes.object,
  openModalForNewOffer: PropTypes.func,
  handleClose: PropTypes.func,
  handleNewOffer: PropTypes.func,
  open: PropTypes.bool,
};
