import { useQuery } from '@tanstack/react-query'
import { useEncryptStorage } from '../encryption/index.js'
import { createUserQueryKey } from './query-key-factory.js'

function select({ data }) {
  const {
    acf: userData,
    id: reader_id,
    name: reader_name,
    buy_button_coisar: shopifyId,
  } = data

  return {
    user: {
      userId: reader_id,
    },
    content: {
      ...userData,
      shopifyId,
      reader_id,
      reader_name,
    },
  }
}

export function useUser(credentials = {}) {
  const {
    username: storedUsername,
    password: storedPassword,
  } = useEncryptStorage(store => store.getCredentials())

  const setCredentials = useEncryptStorage(store => store.setCredentials)
  const username = storedUsername || credentials?.username;
  const password = storedPassword || credentials?.password;
  const userQueryKey = createUserQueryKey({ username, password })
  const enabled = !!username && !!password;

  return useQuery(userQueryKey, {
    enabled,
    select,
    onSuccess: () => {
      setCredentials({ username, password })
    }
  })
}
