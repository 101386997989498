
import {
  Table,
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
  makeStyles,
  TableBody,
  Typography
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";

import { theme } from "../../../lib/index";

import { IconButtonHdc, DeleteButtonWithConfirmation } from "../../../components/index";
import ModalImage from "./ModalImage";

export default function TableHdc(props) {
  const useStyles = makeStyles({
    table: {
      "& .MuiTableCell-root": {
        border: "none",

        verticalAlign: "top",
      },
    },

    head: {
      border: "none",

      background: theme.palette.lightgrey,

      "& th": {
        ...theme.typography.tableHead,
        color: theme.palette.black,

        "&:first-child": {
          width: 150
        },

        "&:last-child": {
          width: 100
        },
      },
    },

    body: {
      borderColor: "transparent",

      background: theme.palette.white,

      "& .MuiTableCell-body": {
        ...theme.typography.tableText,
        color: theme.palette.black,
      },

      "& .MuiTableRow-root:nth-child(even)": {
        background: theme.palette.lightgrey,
      },
    },

    noData: {
      padding: 20,
    }
  });

  const classes = useStyles();

  return (
    <TableContainer className={classes.table}>
      <Table>
        <TableHead className={classes.head}>
          <TableRow>
            <TableCell>Vorschau</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Beschreibung</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        {props.rows ?
          <TableBody className={classes.body}>
            {props.rows.map((row) => (
              <TableRow key={row.id} id={row.id}>
                <TableCell>
                  <ModalImage
                    title={row.name}
                    image={row.image}
                    height="auto"
                    width="125"
                  />
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>
                  <IconButtonHdc onClick={() => props.handleEditCertificate(row)}>
                    <Edit />
                  </IconButtonHdc>
                  <DeleteButtonWithConfirmation
                    deleteFunction={()=> props.handleDeleteCertificate(row.id)}
                    deleteIcon
                    itemToDelete={row.name}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          : <Typography className={classes.noData}> Bisher wurden keine Zertifikate hinzugefügt. </Typography>
        }
      </Table>
    </TableContainer>
  );
}
