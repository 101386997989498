import axios from "axios";
import { encryptStorage } from '../encryption/index.js'

export function patch(resource, data, customAuth) {
  const username = encryptStorage.getItem('username');
  const password = encryptStorage.getItem('password');
  const auth = customAuth || { username, password };
  const endpoint = `${process.env.REACT_APP_ROOT_URI}${resource}`

  return axios.patch(endpoint, data, { auth });
}
