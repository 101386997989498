import { makeStyles } from "@material-ui/core";
import { ArrowDropUp, ArrowDropDown } from "@material-ui/icons";

import { IconButtonHdc } from "../IconButtonHdc/index";

export default function SortIcon({ sorting }) {
  const useStyles = makeStyles({
    sortIconBox: {
      position: "relative",

      marginLeft: 5,
      height: "100%",
    },

    sortIcon: {
      position: "absolute",

      minWidth: 30,

      "&:first-child": {
        top: -5,
      },

      "&:last-child": {
        top: 3,
      },
    },
  });

  const classes = useStyles();

  return (
    <IconButtonHdc className={classes.sortIconBox} onClick={() => sorting()}>
      <ArrowDropUp className={classes.sortIcon} />
      <ArrowDropDown className={classes.sortIcon} />
    </IconButtonHdc>
  );
}
