import { makeStyles } from "@material-ui/core";
import cx from 'classnames'
import PropTypes from "prop-types";

//import placeholderPerson from "../../assets/images/placeholder.png";
import placeholderCertificate from "../../assets/images/upload.png";

export default function Image(props) {
  const useStyles = makeStyles({
    container: {
      width: `${props.width + "px"}`,
      height: `${props.height + "px"}`,
    },

    image: {
      width: `${props.width ? props.width + "px" : "100%"}`,
      height: `${props.height ? props.height + "px" : "100%"}`,

      borderRadius: `${props.circle ? "50%" : 0}`,

      objectFit: "cover",
      objectPosition: "center center",
      aspectRatio: "1 / 1"
    },
  });

  const classes = useStyles();

  return (
    <div className={`${classes.container} ${props.className && props.className}`}>
      <img
        className={cx(classes.image, { 'has-image': !!props.src })}
        src={
          props.src
            ? props.src
            : props.certificate
              ? placeholderCertificate
              : "https://backend.hdc.i-pioneers.de/wp-content/uploads/2022/09/blank-profile-picture-gdd4b8ee94_1280.png"
        }
        alt={props.alt}
      />
    </div>
  );
}

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  circle: PropTypes.bool,
};
