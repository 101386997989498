import { useState } from "react";
import { Grid, makeStyles, Snackbar, Typography } from "@material-ui/core";
import { Alert } from "@mui/material";
import { Headline } from "../../components/index";
import TableOffers from "./TableOffers";
import ModalAddOffer from "./ModalAddOffer";
import { theme, usePatchUser } from "../../lib/index";
import { useOffers } from "./useOffers";
import { formatOffersForApi } from "./formatOffersForApi";

const useStyles = makeStyles({
  snackbar: {
    "& .MuiSnackbarContent-root": {
      minWidth: "fit-content",
    },
    "& .snacktext": {  
      marginRight: 30,
      padding: "12px 24px",
      height: "100%",

      background: theme.palette.darkgrey,

      color: theme.palette.white,
      fontSize: 14,
      textAlign: "center"
    }
  },
});

export default function Offers() {
  const offers = useOffers();
  const { mutateAsync: patchUser } = usePatchUser();

  const [open, setOpen] = useState(false);
  const [confirmSave, setConfirmSave] = useState(false);
  const [chosenOfferId, setChosenOfferId] = useState("");
  const chosenOffer = offers.find((offer) => offer.id === chosenOfferId);

  const classes = useStyles();

  const deleteOffer = (id) => {
    const nextOffers = offers.filter((offer) => offer.id !== id)
    const formatted = formatOffersForApi(nextOffers)
    const patchedData = { reader_offers: formatted }

    return patchUser(patchedData)
  }

  const openModal = (id) => {
    setChosenOfferId(id);
    setOpen(true);
  };
  
  return (
    <>
      <Grid item xs={12}>
        <Headline>Angebote</Headline>
        <ModalAddOffer
          dataToEdit={chosenOffer}
          {...{ openModal }}
          handleClose ={() => setOpen(false)}
          open={open}
        />
      </Grid>
      <Grid item xs={12}>
        { offers.length > 0 ? (
          <TableOffers
            offers={offers}
            {...{ deleteOffer }}
            {...{ openModal }}
          />
        ) : (
          <Typography>Bisher wurden keine Angebote erstellt.</Typography>
        )}
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={10000}
        className={classes.snackbar}
        open={confirmSave}
        onClose={() => setConfirmSave(false)}
      >
        <>
          <Typography className="snacktext">Änderungen gespeichert</Typography>
          <Alert severity="warning">
            Bitte teile uns Deine Angebots-Änderungen per E-Mail <a href="mailto:hello@humandesignclub.com">(hello@humandesignclub.com)</a> mit, damit wir Dein Profil anpassen können. Vielen Dank.
          </Alert>
        </>
      </Snackbar>
    </>
  );
}
