import PropTypes from "prop-types";
import { makeStyles, Typography } from "@material-ui/core";

import { theme, constants as c } from "../../lib/index";
import { Btn, CardHdc } from "../index";

export default function CardDownload(props) {
  let cardBackground = theme.palette.white;
  let textColor = theme.palette.black;
  let buttontype = c.BORDER;

  if (props.background === c.BLACK) {
    cardBackground = theme.palette.black;
    textColor = theme.palette.white;
    buttontype = c.WHITEBORDER;
  }
  if (props.background === c.YELLOW) {
    cardBackground = theme.palette.hdc;
    textColor = theme.palette.black;
    buttontype = c.BORDER;
  }
  if (props.background === c.WHITE) {
    cardBackground = theme.palette.white;
    textColor = theme.palette.black;
    buttontype = c.BORDER;
  }

  const useStyles = makeStyles({
    card: {
      display: "flex",
      margin: `${props.noMargin ? 0 : "0 30px 30px 0"}`,
      padding: 30,
      //minHeight: 280,

      flexDirection: "column",
      alignItems: "flex-start",
      gap: 15,

      background: `${cardBackground}`,
    },

    title: {
      color: `${textColor}`,
    },

    text: {
      ...theme.typography.p,
      color: `${textColor}`,
    },

    button: {
      alignSelf: "center",

      marginTop: 10,
    },
  });

  const classes = useStyles();

  return (
    <CardHdc className={classes.card} shadow={true}>
      <Typography variant="h3" className={classes.title}>
        {props.title}
      </Typography>
      <Typography className={classes.text}>{props.text}</Typography>
      <Btn href={props.downloadLink} className={classes.button} flavor={buttontype} >
        {`${props.buttonName ? props.buttonName : "Download"}`}
      </Btn>
    </CardHdc>
  );
}

CardDownload.propTypes = {
  background: PropTypes.oneOf(["black", "white", "yellow"]),
  buttonName: PropTypes.string,
  downloadLink: PropTypes.string,
  noMargin: PropTypes.bool,
  text: PropTypes.string,
  title: PropTypes.string,
};
