import { useRef, useState } from "react";
import { Grid, Typography, Box, Snackbar } from "@material-ui/core";
import { Stack } from "@mui/material";
import { Field, Form } from "react-final-form";
import {
  constants as c,
  usePatchUser,
  validation as val,
} from "../../../lib/index";
import {
  Btn,
  Headline,
  ToggleButtonFF,
  EscOrSaveButtons,
  InputTextfieldFF,
} from "../../../components/index";
import ToggleButtonFF_newFocus from "./ToggleButtonFF_newFocus";
import { createUpdateFocusPayload } from './createUpdateFocusPayload'
import { deleteFocus } from './deleteFocus'
import { handleAddFocus } from './handleAddFocus'
import { useInitialFormState } from './useInitialFormState'
import { useStyles } from './useStyles'

export default function Focus() {
  const addNewFocus = useRef(null);
  const classes = useStyles();
  const initialFormState = useInitialFormState()
  const [confirmSave, setConfirmSave] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const { mutateAsync: patchUser } = usePatchUser()

  const onSubmit = async values => {
    const patchedData = createUpdateFocusPayload(values);
    await patchUser(patchedData);
    setConfirmSave(true);
    setShowSaveButton(false);
  };

  return (
    <>
      <Grid item xs={12}>
        <Headline>Schwerpunkte</Headline>
      </Grid>
      <Form
        onSubmit={ onSubmit }
        initialValues={initialFormState}
        render={({ handleSubmit, form, dirtyFields, dirty, values }) => (
          <form onSubmit={handleSubmit} className={classes.form}>
            <Stack direction="column" gap={3}>
              <Box>
                <Typography variant="h3" className={classes.headline}>
                  Genre
                </Typography>
                <Typography className={classes.subline}>
                  In welchem Bereich bist du tätig?
                </Typography>
                <Field
                  component={ToggleButtonFF}
                  label="Private Readings"
                  name="privateReadings"
                  type="checkbox"
                />
                <Field
                  component={ToggleButtonFF}
                  label="Business Readings"
                  name="businessReadings"
                  type="checkbox"
                />
              </Box>
              <Box>
                <Typography variant="h3" className={classes.headline}>
                  HD-Spezialgebiete
                </Typography>
                <Field
                  component={ToggleButtonFF}
                  label="Familie & Kinder"
                  name="familyAndKids"
                  type="checkbox"
                />
                <Field
                  component={ToggleButtonFF}
                  label="Liebe & Partnerschaft"
                  name="loveAndRelationship"
                  type="checkbox"
                />
                <Field
                  component={ToggleButtonFF}
                  label="Business"
                  name="business"
                  type="checkbox"
                />
                <Field
                  component={ToggleButtonFF}
                  label="Karriere"
                  name="career"
                  type="checkbox"
                />
              </Box>
              <Box>
                <Typography variant="h3" className={classes.headline}>
                  Deine Schwerpunkte
                </Typography>
                <Typography className={classes.subline}>
                  Was sind deine persönlichen Schwerpunkte?
                </Typography>
                <Field
                  component={ToggleButtonFF}
                  label="Digitalisierung"
                  name="digital"
                  type="checkbox"
                />
                <Field
                  component={ToggleButtonFF}
                  label="Coaching"
                  name="coaching"
                  type="checkbox"
                />
                <Field
                  component={ToggleButtonFF}
                  label="Mediation"
                  name="mediation"
                  type="checkbox"
                />
              </Box>
              <Box>
                <Typography className={classes.weitereSchwerpunkte}>
                  Weitere Schwerpunkte
                </Typography>
                <Stack direction="row" alignItems="center">
                  <Field
                    characterLimit={50}
                    counter
                    className={classes.customTextField}
                    component={InputTextfieldFF}
                    fullWidth
                    inputRef={addNewFocus}
                    name="TextFieldAddNewFocus"
                    noMargin
                    inputProps={{ maxLength: 50 }}
                    onKeyPress={() => handleAddFocus(form, addNewFocus.current.value)}
                    placeholder="Weiterer Schwerpunkt (max. 50 Zeichen)"
                    validate={ val.composeValidators(
                      val.mustBeText,
                      val.limitCharacters50
                    )}
                  />
                  <Btn
                    type="button"
                    disabled={!dirtyFields.TextFieldAddNewFocus}
                    className={classes.addButton}
                    flavor={c.BLACK}
                    onClick={() => handleAddFocus(form)}
                    padding={c.LG}
                  >
                    Hinzufügen
                  </Btn>
                </Stack>
                <Box className={classes.newFocusBox}>
                  {values.additionalFocuses.map((additionalFocus, index) => (
                    <Field
                      component={ToggleButtonFF_newFocus}
                      deleteFocus={id => {
                        deleteFocus(form, id)
                        setShowSaveButton(true);
                      }}
                      id={additionalFocus.id}
                      key={additionalFocus.id}
                      label={additionalFocus.focus}
                      name={`additionalFocuses.${index}`}
                      type="checkbox"
                    />
                  ))}
                </Box>
              </Box>
            </Stack>
            <EscOrSaveButtons
              disabled={!dirty && !showSaveButton}
              handleClose={() => {
                form.reset();
                setShowSaveButton(false);
              }}
              handleSave={handleSubmit}
              typeSave="submit"
              typeClose="button"
            />
          </form>
        )}
      />
      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={2000}
        open={confirmSave}
        onClose={() => setConfirmSave(false)}
        message="Änderungen gespeichert"
      />
    </>
  );
}
