import { useMemo } from 'react'
import { useUser } from '../../lib/index'

export function useRatings() {
  const user = useUser();
  const { content } = user.data;

  return useMemo(() => {
    return (content.reader_rating || []).map(rating => {
      const id = rating.reader_rating_uuid;
      const dateString = "" + rating.reader_rating_date;
      const src = rating.reader_rating_photo;
      const stars = parseInt(rating.reader_rating_stars);
      const text = rating.reader_rating_text;
      const title = rating.reader_rating_name;
      const link = rating.reader_rating_link;
      const date = `${dateString.slice(6)}.${dateString.slice(4,6)}.${dateString.slice(0,4)}`;

      return { id, date, src, stars, text, link, title };
    })
  }, [content.reader_rating])
}
