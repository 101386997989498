import { makeStyles } from "@material-ui/core";
import { theme } from "../../../lib/index";

export const useStyles = makeStyles({
  form: {
    width: "100%"
  },

  headline: {
    marginBottom: 10
  },

  subline: {
    ...theme.typography.pBig,
    marginBottom: 0,

    color: theme.palette.placeholdergrey,
  },

  weitereSchwerpunkte: {
    ...theme.typography.pBig,
    marginTop: 10,
    marginBottom: 10,
  },

  textfields: {
    display: "flex",

    flexWrap: "wrap",
    gap: 50,

    "& > .MuiContainer-root": {
      marginLeft: 0,
      marginRight: 0,
    },
  },

  textfield: {
    paddingTop: 20,
    minWidth: 200,
    maxWidth: 500,

    [theme.breakpoints.down("md")]: {
      margin: 0,
      maxWidth: 1390,
    },
  },

  addButton: {
    alignSelf: "flex-start",

    marginLeft: 50,
    height: 45,
  },

  flexRow: {
    display: "flex",

    flexDirection: "row",
  },

  newFocusBox: {
    display: "flex",
    margin: "20px 0 80px",

    flexDirection: "row",
    flexWrap: "wrap",
  },

  customTextField: {
    margin: 0,
    padding: 0,
    maxWidth: 500,

    "& .MuiInputBase-root": {
      display: "flex",

      alignItems: "center",

      background: theme.palette.white,
    },

    "& .MuiFilledInput-input": {
      padding: "12px 50px 12px 15px",
      border: "1px solid transparent",

      backgroundColor: theme.palette.backgroundgrey,

      transition: `${theme.transitions.create(
        ["border-color", "border-radius"], { duration: theme.transitions.duration.standard }
      )}`,
    },

    "& .MuiInputBase-input:hover": {
      borderColor: `${theme.palette.placeholdergrey}50`,
    },

    "& .MuiInputBase-input:focus": {
      borderColor: theme.palette.hdc,

      borderRadius: 0,
      background: theme.palette.backgroundgrey,
    },

    "& .MuiInputBase-input:focus ~ .MuiInputAdornment-root": {
      opacity: 1,

      transition: `${theme.transitions.create(["opacity"], { duration: theme.transitions.duration.long })}`,
    },

    "& .MuiFilledInput-underline:before,& .MuiFilledInput-underline:after": {
      border: `1px solid transparent`,
    },

    "& .MuiInputAdornment-root": {
      position: "absolute",
      top: 8,
      right: 2,

      height: "auto",

      justifyContent: "flex-start",

      opacity: 0,
    },
  },

  snackbar: {
    "& .MuiSnackbarContent-root": {
      minWidth: "fit-content",
    }
  },
});
