import { makeStyles, Box } from "@material-ui/core";
import { theme } from "../../lib/index"

const useStyles = makeStyles({
  linkbox: {
    position: "relative",

    display: "flex",
    marginBottom: 25,

    justifyContent: "flex-start",
    alignItems: "center",
    gap: 0,

    color: theme.palette.white,

    transition: `${theme.transitions.create(["color"], { duration: theme.transitions.duration.standard, })}`,

    "&:hover": {
      color: theme.palette.midgrey,
    },

    "&:last-child": {
      position: "absolute",
    },
  },

  link: {
    ...theme.typography.menulink,
    display: "block",
    marginLeft: 0,
    paddingLeft: "14px",

    color: "currentColor",
    textDecoration: "none",

    transition: `${theme.transitions.create(["margin-left"], {
      duration: theme.transitions.duration.standard,
    })}`,

    cursor: "pointer",

    "&:hover": {
      marginLeft: 5,
    },
  },
});

export default function LinkListItemExternal({ name, href, icon }) {
  const classes = useStyles();
  return (
    <div className={classes.linkbox} key={name}>
      <Box>{icon}</Box>
      <a href={href} className={classes.link} target="_blank" rel="noreferrer">{name}</a>
    </div> 
  )
}
