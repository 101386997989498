import { useMemo, useState, useEffect } from 'react';
import { makeStyles, Typography, Box, Snackbar, Stepper, Step, StepLabel, useMediaQuery } from '@material-ui/core';
import { Stack } from '@mui/material';
import { Edit, MenuBook } from '@material-ui/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { format, fromUnixTime } from 'date-fns';
import {
  Btn,
  CardHdc,
  IconButtonHdc,
  DeleteButtonWithConfirmation,
} from '../../components/index';
import {
  constants as c,
  dateForApp,
  mapBackendKeyToStateKey,
  theme,
  typeOfReadingMap,
  useUser,
  usePatchUser,
} from '../../lib/index';
import ModalAddReading from './ModalAddReading';
import ShopifyBuyButton from './ShopifyBuyButton';
import { useCustomers } from './useCustomers'

const steps = ['anfordern', 'bezahlen & freigeben', 'nutzen'];

function useOfferedReadingTypes() {
  const user = useUser();
  const { content } = user.data;

  return useMemo(() => {
    if (!content.reader_offers) {
      return [];
    }

    return content.reader_offers.map((offer) =>
      mapBackendKeyToStateKey(offer.reader_offers_type, typeOfReadingMap)
    );
  }, [content.reader_offers])
}

const useStyles = makeStyles({
  card: {
    position: 'relative',
    marginBottom: 50,
    padding: '30px 60px 35px 60px',

    background: theme.palette.white,

    '& .MuiContainer-root': {
      marginBottom: 25,
    },
  },

  customerData: {
    margin: '30px 0',
    padding: '0 12px',
    justifyContent: 'space-between',

    "& .notes": {
      whiteSpace: 'pre-wrap',
    }
  },

  info: {
    maxWidth: "45%",
    textAlign: "right",
  },

  chartDate: {
    paddingLeft: 12,
  },

  coisarBox: {
    margin: '40px 0',
  },

  orderstate: {
    padding: 10,
    border: `1px solid ${theme.palette.lightgrey}`,
    background: theme.palette.backgroundgrey,
    
    '& .orderstate__stepper': {
      backgroundColor: theme.palette.backgroundgrey,
      alignItems: "center",
      
      [theme.breakpoints.up('lg')]: {
        alignItems: "unset"
      }
    },
    '& .orderstate__stack': {
      margin: '15px 0'
    }
  }
});

export default function CardFormCustomerData({
  chosenClient,
  handleEditCustomer,
  updateCustomerAfterOrder,
}) {
  const { mutateAsync: patchUser } = usePatchUser()
  const customersRaw = useCustomers({ suppressTransform: true })

  const deleteCustomer = toDeleteId => {
    const reader_clients = customersRaw.filter(
      ({ reader_clients_uuid }) => reader_clients_uuid !== toDeleteId
    )

    return patchUser({ reader_clients })
  }

  const classes = useStyles();
  const offeredReadingTypes = useOfferedReadingTypes()
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);
  const [progressOfOrder, setProgressOfOrder] = useState(0);
  const smallScreen = useMediaQuery('(min-width: 1350px)') || false;

  useEffect(() => {
    if (chosenClient.coisarStatus === 'none' ) setProgressOfOrder(0);
    if (chosenClient.coisarStatus === 'ordered') setProgressOfOrder(1);
    if (chosenClient.coisarStatus === 'received' && chosenClient.coisarBlocked === true) setProgressOfOrder(1);
    if (chosenClient.coisarStatus === 'received' && chosenClient.coisarBlocked === false) setProgressOfOrder(2);
  }, [chosenClient.coisarStatus, chosenClient.coisarBlocked])

  // returns true if last order is more than 10 min (600 sec) in the past
  const tooMuchTimeSinceLastOrder = (timestampLastOrder) => {
    const now = Math.round(+new Date() / 1000);
    if (now - timestampLastOrder > 600) return true;
  }

  return (
    <>
      <CardHdc className={classes.card} shadow>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant='h3'>Kundendaten</Typography>
          <Box direction='row' spacing={1}>
            { chosenClient.coisarStatus === "none" &&
              <IconButtonHdc onClick={() => handleEditCustomer(chosenClient)}>
                <Edit />
              </IconButtonHdc>
            }
            <DeleteButtonWithConfirmation
              deleteFunction={() => deleteCustomer(chosenClient.id)}
              deleteIcon
              itemToDelete={chosenClient.name}
            />
          </Box>
        </Stack>
        <Stack
          direction='row'
          spacing={3}
          className={classes.customerData}
          alignItems='center'
        >
          <Box>
            <Typography variant='h6'>{chosenClient.name}</Typography>
            <Typography>
              Geboren am {dateForApp(chosenClient.birthdate)} um{' '}
              {chosenClient.birthtime}
            </Typography>
            <Typography>in {chosenClient.birthplace}</Typography>
          </Box>

        </Stack>
        <Box className={classes.orderstate}>
          <Stepper activeStep={progressOfOrder} className="orderstate__stepper" orientation={smallScreen ? "horizontal" : 'vertical'}>
            {steps.map((label) => {
              const stepProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
          {chosenClient.coisarStatus === 'received' ? (
            <Stack className="orderstate__stack" direction={smallScreen ? 'row' : 'column'} alignItems="center" justifyContent="space-evenly" spacing={1}>
              {chosenClient.coisarBlocked ? (
                <>
                  <Typography className={classes.info}>
                    Das angeforderte Chart ist noch nicht freigegeben.
                  </Typography>
                  { chosenClient.shopifyId && <ShopifyBuyButton shopifyId={chosenClient.shopifyId} /> }
                </>
              ) : (
                <>
                  <Btn
                    href={chosenClient.coisarLink}
                    flavor={c.BLACK}
                    startIcon={<MenuBook />}
                  >
                    Coisar öffnen
                  </Btn>
                  <Stack direction={smallScreen ? 'column-reverse' : 'column'} spacing={1} justifyContent='center'>
                    {chosenClient.coisarTimestamp && (
                      <Typography className={classes.chartDate}>
                        Chart vom{' '}
                        {format(
                          fromUnixTime(chosenClient.coisarTimestamp),
                          'dd.MM.yyyy'
                        )}
                      </Typography>
                    )}
                    <CopyToClipboard text={chosenClient.coisarPw}>
                      <Btn padding={c.SM} onClick={() => setShowSaveConfirmation(true)} noUppercase>
                        Passwort: {chosenClient.coisarPw}
                      </Btn>
                    </CopyToClipboard>
                    <Snackbar
                      open={showSaveConfirmation}
                      autoHideDuration={1500}
                      message='Das Passwort wurde in die Zwischenablage kopiert.'
                      onClose={() => setShowSaveConfirmation(false)}
                    />
                  </Stack>
                </>
              )}
            </Stack>
          ) : chosenClient.coisarStatus === 'ordered' && !tooMuchTimeSinceLastOrder(chosenClient.coisarTimestamp) ? (
            <Stack className="orderstate__stack" direction={smallScreen ? 'row' : 'column'} alignItems="center" justifyContent="space-evenly" spacing={1}>
              <Typography className={classes.info}>
              Chart wird abgerufen, bitte warten. Der Prozess kann einige Minuten dauern.
              </Typography>
              { chosenClient.shopifyId && <ShopifyBuyButton shopifyId={chosenClient.shopifyId} /> }
            </Stack>
          ) : chosenClient.coisarStatus === 'ordered' && tooMuchTimeSinceLastOrder(chosenClient.coisarTimestamp) ? (
            <Stack className="orderstate__stack" direction={smallScreen ? 'row' : 'column'} alignItems="center" justifyContent="space-evenly" spacing={1}>
              <Typography className={classes.info}>
              Chart wird abgerufen, bitte warten. Der Prozess kann einige Minuten dauern.
              </Typography>
              <ModalAddReading
                buttonName='Erneut anfordern'
                noMargin
                offeredReadingTypes ={offeredReadingTypes}
                chosenClient={chosenClient}
                handleEditCustomer={handleEditCustomer}
                updateCustomerAfterOrder={updateCustomerAfterOrder}
              />
            </Stack>
          ) : (
            <Box className={classes.customerData}>
              <ModalAddReading
                noMargin
                buttonName='Coisar anfordern'
                offeredReadingTypes={offeredReadingTypes}
                chosenClient={chosenClient}
                handleEditCustomer={handleEditCustomer}
                updateCustomerAfterOrder={updateCustomerAfterOrder}
              />
            </Box>
          )}
        </Box>
        <Box className={classes.customerData}>
          {chosenClient.notes ?
            <>
              <Stack flexDirection="row" justifyContent="start" alignItems="center">
                <Typography variant='h6'>Notizen</Typography>
                <IconButtonHdc onClick={() => handleEditCustomer(chosenClient)}>
                  <Edit />
                </IconButtonHdc>
              </Stack>
              <Typography className="notes">{chosenClient.notes}</Typography>
            </>
            : 
            <Btn onClick={() => handleEditCustomer(chosenClient)} flavor={c.BORDER}>Notizen hinzufügen</Btn>
          }
        </Box>
      </CardHdc>
    </>
  );
}

CardFormCustomerData.propTypes = {};
