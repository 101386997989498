import { useQuery } from '@tanstack/react-query'
import { loadMediaKey } from './query-key-factory.js'

const defaultData = { src: '', alt: '', id: '' };

function formatResponse(input) {
  const { data } = input

  return {
    id: data.id,
    src: data.source_url,
    alt: data.alt_test,
  };
}

export function useMediaQuery({ mediaId }) {
  const queryKey = loadMediaKey(mediaId);

  const result = useQuery(queryKey, {
    enabled: !!mediaId,
    select: formatResponse,
  });

  return {
    ...result,
    data: result.data ?? defaultData,
  };
}
