import { useState } from "react";
import PropTypes from "prop-types";
import { Box, makeStyles, Typography, Container } from "@material-ui/core";
import { Star } from "@material-ui/icons";

import { theme } from "../../lib/index";

export default function StarRating(props) {

  const [currentValue, setCurrentValue] = useState(0);
  const [hoverValue, setHoverValue] = useState(undefined);
  const stars = Array(5).fill(0);

  let count = hoverValue || currentValue;

  const handleClick = (value) => {
    setCurrentValue(value);
  };

  const handleMouseOver = (newHoverValue) => {
    setHoverValue(newHoverValue);
  };

  const handleMouseLeave = () => {
    setHoverValue(undefined);
  };

  const useStyles = makeStyles({
    container: {
      marginBottom: `${props.noMargin ? 0 : 35}`,
      padding: 0,
    },

    box: {
      display: "flex",
      padding: `${props.input ? "12px 15px" : 0}`,
      width: `${props.input ? "initial" : "fit-content"}`,

      flexWrap: "nowrap",
      alignItems: "center",

      background: `${props.input ? theme.palette.backgroundgrey : 0}`,
    },

    label: {
      ...theme.typography.label,
      display: `${props.input ? "block" : "none"}`,
      paddingBottom: "9px",

      color: theme.palette.darkgrey,
    },

    star: {
      marginRight: 10,

      fontSize: 20,

      transition: `${theme.transitions.create(["stroke", "fill"], {
        duration: theme.transitions.duration.standard,
      })}`,

      cursor: `${props.fixedStars ? "initial" : "pointer"}`,
      strokeWidth: 1,
      strokeLinejoin: "round",
    },

    outlined: {
      fill: "transparent",
      stroke: theme.palette.midgrey,
    },

    filled: {
      fill: theme.palette.hdc,
      stroke: theme.palette.hdc,
    },

    text: {
      ...theme.typography.p,
      marginLeft: 2,
      width: 70,

      color: theme.palette.darkgrey,
    },
  });

  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Typography className={classes.label}>Anzahl der Sterne</Typography>
      <Box className={classes.box}>
        {stars.map((_, index) => {
          return (
            <Star
              className={`${classes.star} ${
                props.fixedStars > 0
                  ? props.fixedStars > index
                    ? classes.filled
                    : classes.outlined
                  : (hoverValue || currentValue) > index
                    ? classes.filled
                    : classes.outlined
              } ${props.className && props.className}`}
              key={index}
              onClick={() => handleClick(index + 1)}
              onMouseOver={() => handleMouseOver(index + 1)}
              onMouseLeave={handleMouseLeave}
            />
          );
        })}
        {!props.fixedStars && (
          <Typography className={classes.text}>
            {count ? count : "0"}
            {count !== 1 ? " Sterne" : " Stern"}
          </Typography>
        )}
      </Box>
    </Container>
  );
}

StarRating.propTypes = {
  fixedStars: PropTypes.number,
  input: PropTypes.bool,
  noMargin: PropTypes.bool,
};
