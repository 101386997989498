import HubspotForm from 'react-hubspot-form';
import { Grid } from '@material-ui/core';
import { Headline, Spinner } from '../../components/index';

function Support() {
  return (
    <>
      <Grid item xs={12}>
        <Headline>Support</Headline>
      </Grid>
      <Grid item xs={6}>
        <HubspotForm
          portalId='25242252'
          formId='4a4a1b04-8bd0-458f-9a43-92e041776f6f'
          onSubmit={() => console.log('Submit!')}
          onReady={() => console.log('Form ready!')}
          loading={<Spinner />}
        />
      </Grid>
    </>
  );
}

export default Support;
