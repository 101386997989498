import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  TextField,
  InputLabel,
  InputAdornment,
  makeStyles,
  Container,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";

import { IconButtonHdc } from "../IconButtonHdc/index";
import { theme } from "../../lib/index";

export default function InputTextfieldFF({ input, meta, ...rest }) {
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(input.value);
  }, [input.value]);

  const useStyles = makeStyles({
    container: {
      marginBottom: `${rest.noMargin ? 0 : "35px"}`,
      padding: 0,
    },

    label: {
      ...theme.typography.label,
      display: `${!rest.label && "none"}`,
      paddingBottom: "9px",

      color: theme.palette.darkgrey,
    },

    textField: {
      "& .MuiInputBase-root": {
        ...theme.typography.p,
        display: "flex",
        padding: 0,

        alignItems: "center",

        background: theme.palette.white,

        "&.MuiInputAdornment-root:hover": {
          cursor: "wait",
        },
      },

      "& .MuiFilledInput-input": {
        padding: "12px 50px 12px 15px",
        border: `${
          meta.touched && meta.error
            ? "1px solid " + theme.palette.alert.main
            : "1px solid transparent"
        }`,

        backgroundColor: theme.palette.backgroundgrey,

        transition: `${theme.transitions.create(
          ["border-color", "border-radius"], { duration: theme.transitions.duration.standard, }
        )}`,
      },

      "& .MuiInputBase-input:hover": {
        borderColor: `${theme.palette.placeholdergrey}50`,
      },

      "& .MuiInputBase-input:focus": {
        borderColor: theme.palette.hdc,

        borderRadius: 0,
        background: theme.palette.backgroundgrey,
      },

      "& .MuiInputBase-input:focus ~ .MuiInputAdornment-root": {
        opacity: 1,

        transition: `${theme.transitions.create(["opacity"], {
          duration: theme.transitions.duration.long,
        })}`,

        pointerEvents: "all",

        "&:hover": {
          cursor: "auto",
        },
      },

      "& .MuiFilledInput-underline:before,& .MuiFilledInput-underline:after": {
        border: `1px solid transparent`,
      },

      "& .MuiFormHelperText-root": {
        ...theme.typography.pSmall,
        display: `${rest.counter ? "block" : "none"}`,

        color: `${
          input.value.length > rest.characterLimit
            ? theme.palette.alert.main
            : theme.palette.placeholdergrey
        }`,
        fontWeight: `${input.value.length > rest.characterLimit ? 600 : 400}`,
        textAlign: "right",
      },

      "& .MuiInputAdornment-root": {
        position: "absolute",
        top: 8,
        right: 2,

        height: "auto",

        justifyContent: "flex-start",

        opacity: 0,

        pointerEvents: "none"
      },
    },

    errorMessage: {
      color: theme.palette.alert.main,
    },
  });

  const classes = useStyles();

  const handleKeyPress = (e) => {
    if (rest.onKeyPress && e.charCode === 13) {
      e.preventDefault();
      rest.onKeyPress();
    }
  };

  return (
    <Container
      className={`${classes.container} ${rest.className && rest.className}`}
    >
      <InputLabel className={classes.label}>
        {rest.label}
        {rest.required && " *"}
      </InputLabel>
      <TextField
        className={classes.textField}
        disabled = {rest.disabled}
        fullWidth={rest.fullWidth}
        helperText={`${value.length}/${rest.characterLimit}`}
        inputProps={rest.inputProps}
        InputProps={
          rest.login
            ? null
            : {
              endAdornment: (
                <InputAdornment
                  onMouseDown={() => {
                    setValue("");
                    input.onChange("");
                  }}
                  position="end"
                >
                  <IconButtonHdc>
                    <Delete/>
                  </IconButtonHdc>
                </InputAdornment>
              ),
            }
        }
        inputRef={rest.inputRef}
        maxRows={rest.maxRows}
        multiline={rest.multiline}
        onChange={(e) => {
          setValue(e.target.value);
          input.onChange(e.target.value);
        }}
        onKeyPress={handleKeyPress}
        placeholder={rest.placeholder}
        required={rest.required}
        rows={rest.rows}
        type={input.type || "text"}
        variant="filled"
        value={input.value}
      ></TextField>
      {meta.touched && meta.error && (
        <span className={classes.errorMessage}>{meta.error}</span>
      )}
    </Container>
  );
}

InputTextfieldFF.propTypes = {
  characterLimit: PropTypes.number,
  counter: PropTypes.bool,
  label: PropTypes.string,
  maxRows: PropTypes.number,
  multiline: PropTypes.bool,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.number,
};
