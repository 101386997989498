import { ThemeProvider } from "@material-ui/core";
import { QueryClientProvider } from '@tanstack/react-query'
import { BrowserRouter as Router } from "react-router-dom";
import { theme } from "../lib/index";
import { queryClient } from './queryClient.js'
import { App } from './App.js'


import * as Sentry from "@sentry/react";
import { HttpClient } from "@sentry/integrations";

const getSentryEnvironment = () => {
  if (process.env.NODE_ENV === "production") {
    if(window.location.hostname.search("staging") !== -1) {
      return "staging";
    } else {
      return "production";
    }
  } else {
    return "development";
  }
}

Sentry.init({
  environment: getSentryEnvironment(),
  dsn: "https://06609f81105c50ce1677e5bb28ac1c46@o4506042722549760.ingest.sentry.io/4506042723991552",
  integrations: [
    new Sentry.Replay(),
    new HttpClient()
  ],
  sendDefaultPii: true,

  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export function AppWrapper() {
  const { REACT_APP_VERSION, REACT_APP_NAME } = process.env;
  console.log("=== START ===", REACT_APP_NAME, REACT_APP_VERSION);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <Router>
          <App />
        </Router>
      </ThemeProvider>
    </QueryClientProvider>
  );
}
