import styled from '@emotion/styled';
import { keyframes } from '@mui/styled-engine';

export default function Spinner() {
  return (
    <StyledBox >
      <Dot />
      <Dot />
      <Dot />
    </StyledBox>
  )
}

const spinAnimation = keyframes`
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const StyledBox = styled("div", {})({
  display: "flex",
  width: "100%",
  minHeight: 500,

  justifyContent: "center",
  alignItems: "center"
});

const Dot = styled("div", {})({
  float: "left",

  margin: "0 10px",
  width: 20,
  height: 20,

  borderRadius: "50%",
  border: "4px solid #000",

  transform: "scale(0)",

  animation: `${spinAnimation} 1000ms infinite ease`,

  "&:nth-of-type(2)": {
    animation: `${spinAnimation} 1000ms  300ms infinite ease`,
  },

  "&:nth-of-type(3)": {
    animation: `${spinAnimation} 1000ms 600ms infinite ease`,
  },
});