import { useState } from "react";
import { Field, Form } from "react-final-form";
import { makeStyles, Grid, Snackbar } from "@material-ui/core";
import { Stack } from "@mui/material";
import { Headline, EscOrSaveButtons, InputTextfieldFF } from "../../../components/index";
import { validation as val, usePatchUser } from "../../../lib/index";
import { useInitialFormState } from './useInitialFormState'

const useStyles = makeStyles({
  form: {
    width: "100%"
  },

  snackbar: {
    "& .MuiSnackbarContent-root": {
      minWidth: "fit-content",
    }
  },
});

export default function Vision() {
  const { mutateAsync: patchUser } = usePatchUser()
  const initialFormState = useInitialFormState()
  const [confirmSave, setConfirmSave] = useState(false);
  const classes = useStyles();

  const onSubmit = async (values) => {
    const patchedData = {
      reader_profile_focus_vision: values.vision || "",
      reader_profile_focus_visiondetail: values.visionExplained || "",
    };

    await patchUser(patchedData)
    setConfirmSave(true)
  };

  return ( 
    <>
      <Grid item xs={12}>
        <Headline>Vision</Headline>
      </Grid>
      <Grid item xs={12} xl={6}>
        <Form
          onSubmit={ onSubmit }
          initialValues={initialFormState}
          render={({ handleSubmit, form, dirty }) => {
            return (
              <form id="formVision" onSubmit={handleSubmit}>
                <Stack gap={5}>
                  <Field
                    component={InputTextfieldFF}
                    fullWidth
                    label="Meine Vision"
                    name="vision"
                    noMargin
                    placeholder="Was ist deine Vision in einem Satz?"
                    type="text"
                  />
                  <Field
                    characterLimit={500}
                    component={InputTextfieldFF}
                    counter
                    label="Erläuterung der Vision"
                    fullWidth
                    name="visionExplained"
                    noMargin
                    multiline
                    placeholder="Wofür stehst du? (max. 500 Zeichen)"
                    rows={5}
                    type="text"
                    validate={val.composeValidators(val.mustBeText, val.limitCharacters500)}
                  />
                </Stack>
    
                <EscOrSaveButtons
                  disabled = {!dirty}
                  typeSave="submit"
                  typeClose="button"
                  handleSave={handleSubmit}
                  handleClose={form.reset}
                />
              </form>
            );
          }}
        />
      </Grid>
      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={2000}
        open={confirmSave}
        onClose={() => setConfirmSave(false)}
        message="Änderungen gespeichert"
      />
    </>
  )
}
