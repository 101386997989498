import { makeStyles } from "@material-ui/core";
import {
  AccountCircleOutlined,
  LocalOfferOutlined,
  StarOutlined,
  ExitToAppOutlined,
  PeopleOutline,
  HelpOutline,
  Description
} from '@material-ui/icons';

import { theme } from "../../lib/index";
import logo from "../../assets/images/logo.png";
import AGB from "../../assets/documents/_HD24.de AGB_Backend.pdf";

import { LinkListItem, LinkListItemExternal, LinkList } from "../../components/index";

const useStyles = makeStyles({
  navBar: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 10,

    minWidth: theme.menuWidthDesktop,
    height: "100%",
    minHeight: "100vh",

    background: theme.palette.black,
    color: theme.palette.white,
  },

  logo: {
    margin: 30,
    width: "100%",
    maxWidth: 164,
    minHeight: 67,

    background: `url(${logo})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },

  hr: {
    margin: 0,
    padding: 0,
    height: 1,
    border: "none",

    background: theme.palette.placeholdergrey,
  },
});

export default function Navbar() {
  const classes = useStyles();

  return (
    <div className={classes.navBar} >
      <a href="/profil/steckbrief" >
        <div className={classes.logo} />
      </a>
      <hr className={classes.hr} />
      <LinkList>
        <LinkListItem
          name="Profil"
          path="/profile/personal"
          icon={<AccountCircleOutlined />}
        />
        <LinkListItem
          name="Angebote"
          path="/offers"
          icon={<LocalOfferOutlined />}
        />
        <LinkListItem
          name="Bewertungen"
          path="/ratings"
          icon={<StarOutlined />}
        />
        <LinkListItem
          name="Support"
          path="/support"
          icon={<HelpOutline />}
        />
        <LinkListItemExternal
          name="AGB"
          href={AGB}
          icon={<Description />}
        />
        <LinkListItem 
          name='Abmelden'
          path='/logout'
          icon={<ExitToAppOutlined />}
          moveToBottom
        />
      </LinkList>
    </div>
  );
}
