import { useEffect, useState } from "react";
import { Outlet } from 'react-router-dom'
import { Spinner } from "../../components/index";
import { useUser } from "../../lib/index";
import LoginPage from "./LoginPage";

export default function Login() {
  // prevents unnecessary initial display of login screen on first render
  const [credentials, setCredentials] = useState(null)
  const user = useUser(credentials)

  // Ensures that there's no data set when the user logs out
  useEffect(() => {
    if (user.data) {
      setCredentials(null)
    }
  }, [user.data])

  // show spinner while loading
  if (
    user.fetchStatus !== 'idle' &&
    user.isLoading
  ) {
    return <Spinner />
  }

  // show Login-Form if not logged in yet
  else if (!user.data) {
    return (
      <LoginPage
        handleLogin={setCredentials}
        failed={user.isError}
      />
    );
  }

  // display app if logged in
  return (
  /*     <div id="logged-in-content"> */
    <Outlet />
  /*     </div> */
  );
}
