import PropTypes from "prop-types";
import { Card, makeStyles } from "@material-ui/core";

export default function CardHdc(props) {
  const useStyles = makeStyles({
    card: {
      boxShadow: `${props.shadow && "4px 4px 16px rgba(0, 0, 0, 0.15)"}`,
    },
  });
  const classes = useStyles();

  return (
    <Card
      id={props.id}
      className={`${classes.card} ${props.className && props.className}`}
    >
      {props.children}
    </Card>
  );
}

CardHdc.propTypes = {
  id: PropTypes.string,
  shadow: PropTypes.bool,
  children: PropTypes.any,
};
