import { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";
import { Container, Typography } from "@material-ui/core";
import { Form, Field } from "react-final-form";
import {
  Image,
  ModalHdc,
  UploadButton,
  EscOrSaveButtons,
  InputTextfieldFF,
} from "../../../components/index";
import {
  useMediaQuery,
  constants as c,
  validation as val,
} from "../../../lib/index";
import { useModalAddCertificateStyles } from './useModalAddCertificateStyles'
import { useHandleNewCertificate } from './useHandleNewCertificate'

export default function ModalAddCertificate({
  dataToEdit,
  handleClickOpen,
  handleClose,
  open,
}) {
  const handleNewCertificate = useHandleNewCertificate()
  const [uploadedImageId, setUploadedImageId] = useState(dataToEdit.image);
  const nodeRef = useRef(null);
  const image = useMediaQuery({ mediaId: uploadedImageId })
  const classes = useModalAddCertificateStyles();

  useEffect(() => {
    setUploadedImageId(dataToEdit.image)
  }, [dataToEdit.image])

  const onSubmit = async (certificateData) => {
    const fullCertificateData = {
      id: uuid(),
      ...certificateData,
      image: uploadedImageId || certificateData.image
    };
    handleNewCertificate(fullCertificateData);
    setUploadedImageId("");
    handleClose();
  };

  const handleImageUpload = useCallback((data) => {
    setUploadedImageId(data);
  }, []);

  const closeAndClear = () => {
    setUploadedImageId("");
    handleClose();
  };

  return (
    <ModalHdc
      buttonName="Zertifikat hinzufügen"
      buttonType={c.BLACK}
      className={classes.modal}
      fullWidth
      nodeRef={nodeRef}
      open={open}
      handleClose={closeAndClear}
      handleClickOpen={handleClickOpen}
    >
      <Typography variant="h3" className={classes.title}>
        Zertifikat hinzufügen
      </Typography>
      <Form
        onSubmit={onSubmit}
        initialValues={{ ...dataToEdit }}
        render={({ handleSubmit, dirty }) => (
          <form onSubmit={handleSubmit}>
            <Container className={classes.flex}>
              <Container className={classes.container}>
                <Image
                  certificate
                  className={classes.image}
                  src={image.data.src}
                />
                <Typography className={classes.subSubline}>
                  .jpg-, .jpeg, .png oder .webp-Format, max. 1MB
                </Typography>
                <UploadButton image handleUploadedFile={handleImageUpload}>
                  Bild hochladen
                </UploadButton>
              </Container>
              <Container className={classes.container}>
                <Field
                  component={InputTextfieldFF}
                  fullWidth
                  label="Name"
                  name="name"
                  placeholder="Um welches Zertifikat handelt es sich?"
                  required
                  type="text"
                  validate={val.composeValidators(val.mustBeText, val.required)}
                  value={dataToEdit?.name}
                />
                <Field
                  characterLimit={250}
                  component={InputTextfieldFF}
                  counter
                  fullWidth
                  label="Kurzbeschreibung"
                  name="description"
                  multiline
                  placeholder="Gib kurz einen Überblick über die Inhalte (max. 250 Zeichen)"
                  rows={5}
                  type="text"
                  validate={val.composeValidators(val.mustBeText, val.limitCharacters250)}
                />
              </Container>
            </Container>
            <EscOrSaveButtons
              disabled={!dirty}
              handleClose={handleClose}
              handleSave={handleSubmit}
              modal
              typeSave="submit"
            />
          </form>
        )}
      />
    </ModalHdc>
  );
}

ModalAddCertificate.propTypes = {
  dataToEdit: PropTypes.object,
  handleClickOpen: PropTypes.func,
  handleClose: PropTypes.func,
  handleNewCertificateData: PropTypes.func,
  open: PropTypes.bool,
};
