import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { Facebook, Instagram, LinkedIn, Twitter, YouTube, Web } from "@material-ui/icons";
import { Stack } from "@mui/material";
import { CardHdc, Image, DeleteButtonWithConfirmation } from "../../components/index";
import { usePatchUser, theme, loadMedia } from "../../lib/index";
import StarRating from "./StarRating";
import { useRatings } from './useRatings.js'
import { formatRatingsForApi } from './formatRatingsForApi'

export default function CardRating({ rating }) {
  const ratings = useRatings()
  const { mutateAsync: patchUser } = usePatchUser()
  const [image, setImage] = useState({});

  function deleteRating(id) {
    const nextRatings = ratings.filter((e) => e.id !== id);
    const formatted = formatRatingsForApi(nextRatings);
    const patchedData = { reader_rating: formatted }
    return patchUser(patchedData)
  }

  useEffect(() => {
    let isMounted = true;
    rating.src && isMounted && loadMedia(rating.src, setImage);
    return () => { isMounted = false };
  }, [rating]);

  const useStyles = makeStyles({
    card: {
      position: "relative",

      padding: "30px 50px 115px 50px",

      background: theme.palette.white,
    },

    box: {
      display: "flex",

      gap: 30,
    },

    stars: {
      margin: "11px 0",
    },

    dateText: {
      ...theme.typography.p,

      color: theme.palette.placeholdergrey,
    },

    text: {
      ...theme.typography.p,
      marginTop: 25,
    },

    link: {
      color: theme.palette.darkgrey,
      textDecoration: "none"
    },

    button: {
      position: "absolute",
      right: 30,
      bottom: 45,
    },
  });

  const classes = useStyles();

  return (
    <CardHdc className={classes.card} id={rating.id} shadow>
      <Box className={classes.box}>
        <Image width="100" height="100" src={image.src} circle />
        <Box className={classes.innerbox}>
          <Typography variant="h6" className={classes.title}>
            {rating.title}
          </Typography>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <StarRating
              fixedStars={rating.stars}
              className={classes.stars}
              noMargin
            />
            {rating.link &&
              <a href={rating.link.indexOf("https://") || rating.link.indexOf("http://")
                ? rating.link
                : `https://${rating.link}`}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
              >
                {rating.link.indexOf("twitter") !== -1
                  ? <Twitter />
                  : rating.link.indexOf("facebook") !== -1
                    ? <Facebook />
                    : rating.link.indexOf("instagram") !== -1
                      ? <Instagram />
                      :rating.link.indexOf("youtube") !== -1
                        ? <YouTube />
                        : rating.link.indexOf("linkedin") !== -1
                          ? <LinkedIn />
                          : <Web />
                }
              </a>
            }
          </Stack>
          <Typography className={classes.dateText}>
            Rezension vom {rating.date}
          </Typography>
        </Box>
      </Box>
      <Typography className={classes.text}>{rating.text}</Typography>
      <DeleteButtonWithConfirmation
        className={classes.button}
        deleteFunction={() => deleteRating(rating.id)}
        itemToDelete={rating.title}
      />
    </CardHdc>
  );
}

CardRating.propTypes = {
  handleDeleteRating: PropTypes.func,
  rating: PropTypes.object,
};
