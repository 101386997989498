import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
  makeStyles,
  TableBody,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";

import { theme } from "../../lib/index";

import { IconButtonHdc, SortIcon, DeleteButtonWithConfirmation } from "../../components/index";

const ASC = 'ascending'
const DESC = 'descending'

function useSortedOffers(offers) {
  const [sorted, setSorted] = useState(offers)
  const [sortBy, setSortBy] = useState('')
  const [tableSorting, setTableSorting] = useState(ASC);
  const invertSorting = () => setTableSorting(prev => prev === ASC ? DESC : ASC)

  useEffect(() => {
    if (sortBy) {
      setSorted(
        offers.slice().sort((a, b) => {
          const left = isNaN(a[sortBy]) ? a[sortBy]?.toLowerCase() : a[sortBy]
          const right = isNaN(b[sortBy]) ? b[sortBy]?.toLowerCase() : b[sortBy]
          return tableSorting === ASC
            ? left > right ? 1 : -1
            : left < right ? 1 : -1
        })
      )
    } else {
      setSorted(offers)
    }
  }, [offers, sortBy, tableSorting])

  return {
    setSortBy,
    sortBy,
    sorted,
    setTableSorting,
    invertSorting,
  }
};

const useStyles = makeStyles({
  table: {
    "& .MuiTableCell-root": {
      border: "none",

      verticalAlign: "top",
    },
  },
  head: {
    border: "none",

    background: theme.palette.lightgrey,

    "& th": {
      ...theme.typography.tableHead,
      color: theme.palette.black,

      "&:last-child": {
        width: 100,
      },
    },
  },

  body: {
    borderColor: "transparent",
    background: theme.palette.white,

    "& .MuiTableCell-body": {
      ...theme.typography.tableText,
      color: theme.palette.black,
    },

    "& .MuiTableRow-root:nth-child(even)": {
      background: theme.palette.lightgrey,
    },
  },

  price: {
    minWidth: 110,
  },

  duration: {
    minWidth: 85,
  },
});

export default function TableOffers({ offers, openModal, deleteOffer }) {
  const {
    sorted: rows,
    setSortBy,
    sortBy,
    setTableSorting,
    invertSorting,
  } = useSortedOffers(offers)

  const classes = useStyles();

  return (
    <TableContainer className={classes.table}>
      <Table>
        <TableHead className={classes.head}>
          <TableRow>
            <TableCell>
              Art
              <SortIcon sorting={() => {
                if (sortBy === 'typeOfReading') {
                  invertSorting()
                } else {
                  setSortBy('typeOfReading')
                  setTableSorting(ASC)
                }
              }} />
            </TableCell>
            <TableCell>Business</TableCell>
            <TableCell className={classes.price}>
              Preis
              <SortIcon sorting={() => {
                if (sortBy === 'price') {
                  invertSorting()
                } else {
                  setSortBy('price')
                  setTableSorting(ASC)
                }
              }} />
            </TableCell>
            <TableCell className={classes.duration}>
              Dauer
              <SortIcon sorting={() => {
                if (sortBy === 'duration') {
                  invertSorting()
                } else {
                  setSortBy('duration')
                  setTableSorting(ASC)
                }
              }} />
            </TableCell>
            <TableCell>zusätzliche Leistungen</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.body}>
          {rows?.map((row) => (
            <TableRow key={row.id} id={row.id}>
              <TableCell>
                {row.typeOfReading !== "unknown"
                  ? row.typeOfReading
                  : "Unbekannter Reading-Typ. Bitte korrigieren!"}
              </TableCell>
              <TableCell>
                {row.typeOfReading === "Business Reading" ? "ja" : "nein"}
              </TableCell>
              <TableCell>{row.price} €</TableCell>
              <TableCell>{row.duration} Min.</TableCell>
              <TableCell>{row.additionalBenefits}</TableCell>
              <TableCell>
                <IconButtonHdc onClick={() => openModal(row.id)}>
                  <Edit />
                </IconButtonHdc>
                <DeleteButtonWithConfirmation
                  deleteFunction={() => deleteOffer(row.id)}
                  deleteIcon
                  itemToDelete={row.typeOfReading}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

TableOffers.propTypes = {
  offers: PropTypes.array,
  deleteOffer: PropTypes.func,
  openModal: PropTypes.func,
};
