const reTime = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
const reYoutube = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
const reUrl = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_+.~#?&//=]*)/;
const reUstID = /[A-Z]{2}[0-9]{9}$/;

export const required = (value) => (value ? undefined : "Bitte ausfüllen");

export const mustBeText = (value) =>
  isNaN(value) ? undefined : "Bitte nur Text eingeben.";

export const mustBeNumber = (value) =>
  isNaN(value) ? "Bitte nur Zahlen eingeben." : undefined;

export const mustBeDate = (value) =>
  value ? undefined : "Datum nicht korrekt.";

export const mustBeTime = (value) =>
  value.match(reTime) ? undefined : "Zeitformat nicht korrekt";

export const limitCharacters2000 = (value) =>
  !value || value?.length <= 2000 ? undefined : "Zu langer Text";

export const limitCharacters500 = (value) =>
  !value || value?.length <= 500 ? undefined : "Zu langer Text";

export const limitCharacters250 = (value) =>
  !value || value?.length <= 250 ? undefined : "Zu langer Text";

export const limitCharacters50 = (value) =>
  !value || value?.length <= 50 ? undefined : "Zu langer Text";

export const composeValidators = (...validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const mustBeUstID = (value) =>
  !value || reUstID.test(value) ? undefined : "Keine valide Umsatzsteuer-ID";

export const mustBeUrl = (value) =>
  !value || reUrl.test(value) ? undefined : "Keine valide URL";

export const youtube = (value) => {
  if (!value) {
    return undefined;
  } else if (
    value !== undefined &&
    value !== "" &&
    value.match(reYoutube) &&
    value.match(reYoutube)[2].length === 11
  ) {
    return undefined;
  }
  return "Kein valider Youtube-Link";
};

/* Validate IBAN
 * Returns 1 if the IBAN is valid
 * Returns FALSE if the IBAN's length is not as should be (for CY the IBAN Should be 28 chars long starting with CY )
 * Returns any other number (checksum) when the IBAN is invalid (check digits do not match)
 */

export function validateIBAN(value) {
  return value === "" ? undefined : isValidIBANNumber(value) === 1 ? undefined : "Keine valide IBAN.";
}

function isValidIBANNumber(input) {
  if (input === undefined) return 1;
  let CODE_LENGTHS = {
    AD: 24,
    AE: 23,
    AT: 20,
    AZ: 28,
    BA: 20,
    BE: 16,
    BG: 22,
    BH: 22,
    BR: 29,
    CH: 21,
    CR: 21,
    CY: 28,
    CZ: 24,
    DE: 22,
    DK: 18,
    DO: 28,
    EE: 20,
    ES: 24,
    FI: 18,
    FO: 18,
    FR: 27,
    GB: 22,
    GI: 23,
    GL: 18,
    GR: 27,
    GT: 28,
    HR: 21,
    HU: 28,
    IE: 22,
    IL: 23,
    IS: 26,
    IT: 27,
    JO: 30,
    KW: 30,
    KZ: 20,
    LB: 28,
    LI: 21,
    LT: 20,
    LU: 20,
    LV: 21,
    MC: 27,
    MD: 24,
    ME: 22,
    MK: 19,
    MR: 27,
    MT: 31,
    MU: 30,
    NL: 18,
    NO: 15,
    PK: 24,
    PL: 28,
    PS: 29,
    PT: 25,
    QA: 29,
    RO: 24,
    RS: 22,
    SA: 24,
    SE: 24,
    SI: 19,
    SK: 24,
    SM: 27,
    TN: 24,
    TR: 26,
    AL: 28,
    BY: 28,
    EG: 29,
    GE: 22,
    IQ: 23,
    LC: 32,
    SC: 31,
    ST: 25,
    SV: 28,
    TL: 23,
    UA: 29,
    VA: 22,
    VG: 24,
    XK: 20,
  };
  let iban = String(input)
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, ""), // keep only alphanumeric characters
    code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
    digits;
  // check syntax and length
  if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
    return false;
  }
  // rearrange country code and check digits, and convert chars to ints
  digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
    return letter.charCodeAt(0) - 55;
  });
  // final check
  return mod97(digits);
}

function mod97(string) {
  let checksum = string.slice(0, 2),
    fragment;
  for (let offset = 2; offset < string.length; offset += 7) {
    fragment = String(checksum) + string.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }
  return checksum;
}
